import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Profile as ProfileComponent } from "../../components";
import auth from "services/Auth";

import Button from "@material-ui/core/Button";

const Profile = (props) => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState("");
  const [mode, setMode] = useState("load");

  const handleVerifyEmail = (factor) => {
    const email = props.profile.new_email_addr || props.profile.email;
    auth
      .verifyemail(factor, email)
      .then((succcess) => {
        setProfile((prevAttributes) => ({
          ...prevAttributes,
          verified_email: email,
        }));
      })
      .catch((error) => {});
  };
  const handleVerifyPhone = (factor) => {
    auth
      .verifyemail(factor, profile.email, profile.phone)
      .then((succcess) => {
        setProfile((prevAttributes) => ({
          ...prevAttributes,
          verified_phone: profile.phone,
        }));
      })
      .catch((error) => {});
  };
  const handleSave = () => {
    auth
      .patchUserProfile(profile)
      .then((succcess) => {
        console.log("SAVED");
        setMode("edit");
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
  const handleChange = (key, value) => {
    setMode("changed");
    setProfile((prevAttributes) => ({
      ...prevAttributes,
      [key]: value,
    }));
  };
  useEffect(() => {
    auth
      .userProfile()
      .then((profile) => {
        setProfile(profile);
        setMode("edit");
      })
      .catch((error) => {
        console.error("Error fetching attributes:", error);
      });
  }, []);

  if (mode === "load") {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ProfileComponent
        profile={profile}
        verifyPhone={handleVerifyPhone}
        verifyEmail={handleVerifyEmail}
        changeProp={handleChange}
        save={handleSave}
        close={props.close}
        mode={mode}
      />
    </div>
  );
};

export default Profile;
