import React from "react";
import { GroupsDashboardsNavDrawer as _GroupsDashboardsNavDrawer } from "components";
import { useSelector } from "react-redux";

const GroupsDashboardsNavDrawerComponent = React.memo(
  _GroupsDashboardsNavDrawer
);

const GroupsDashboardsNavDrawer = (props) => {
  const dashboards = useSelector((state) => state.isx.dashboards);
  const orgs = useSelector((state) => state.isx.orgs);
  const user = useSelector((state) => state.isx.user);

  return (
    <GroupsDashboardsNavDrawerComponent
      open={props.open}
      closeDrawer={props.closeDrawer}
      addDashboard={props.addDashboard}
      dashboards={dashboards}
      orgs={orgs}
      user={user}
    />
  );
};

export default GroupsDashboardsNavDrawer;
