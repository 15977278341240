import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  input: {
    fontSize: (props) => props.fontSize ?? "0.875rem",
  },
  option: {
    fontSize: (props) => props.fontSize ?? "0.75rem",
  },
}));

const ComboBox = (props) => {
  const { disableUnderline, disableClearable, ...rest } = props;

  const classes = useStyles(rest);
  return (
    <Autocomplete
      {...rest}
      classes={{
        input: classes.input,
        option: classes.option,
      }}
      disableClearable={disableClearable ?? true}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant={props.variant}
          margin="dense"
          inputProps={{
            ...params.inputProps,
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: disableUnderline,
          }}
          InputLabelProps={{
            // ...params.InputLabelProps,
            style: {fontSize:"0.75rem"}
          }}
        />
      )}
    />
  );
};

export default ComboBox;
