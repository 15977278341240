import React, { useState, useEffect } from "react";
import { Route, Navigate, Routes } from "./services/Router";
import { useLocation, useNavigate } from "react-router-dom";
import {
  NavigationBar,
  Login,
  ResetPassword,
  GroupsDashboardsNavDrawer,
  Dashboard,
  LoginCallback,
  Profile,
  USBTool,
} from "./containers";
import { isxOperations } from "./store";
import { connect } from "react-redux";
import "react-virtualized/styles.css";

import { DashboardsContextProvider } from "services/Utils";

const PrivateRouteComponent = ({ refresh, isAuthenticated, children }) => {
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    (async () => {
      await refresh();
      setLoading(false);
    })();
  }, [refresh]);

  if (loading) {
    return null;
  }

  if (isAuthenticated) {
    return children;
  }

  return (
    <Navigate to="/login" state={{ from: { pathname: location.pathname } }} />
  );
};

const PrivateRoute = connect(
  ({ isx: { isAuthenticated } }) => ({ isAuthenticated }),
  (dispatch) => ({
    refresh: () => dispatch(isxOperations.attemptRefresh()),
  })
)(PrivateRouteComponent);

const App = (props) => {
  const navigate = useNavigate();
  return (
    <DashboardsContextProvider>
      <NavigationBar logo={props.logo} />
      <Routes>
        <Route
          path="/dashboard/:dashboardGuuid?"
          element={
            <PrivateRoute>
              <GroupsDashboardsNavDrawer />
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<LoginCallback />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/changepass" element={<ResetPassword />} />
        <Route
          exact
          path="/profile"
          element={
            <PrivateRoute>
              <Profile close={() => navigate("/")} />
            </PrivateRoute>
          }
        />
        <Route path="/usbtool" element={<USBTool />} />
      </Routes>
    </DashboardsContextProvider>
  );
};

export default App;
