import React, { useEffect, useState, useRef, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import { Responsive, WidthProvider } from "react-grid-layout";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import grey from "@material-ui/core/colors/grey";
import "react-grid-layout/css/styles.css";
import {
  NewWidgetDialog,
  ShareTemplateDialog,
  ShareDashboardDialog,
} from "containers";
import StarIcon from "@material-ui/icons/Star";
import DuplicateIcon from "@material-ui/icons/FileCopy";
import ShareIcon from "@material-ui/icons/Share";
import GroupIcon from "@material-ui/icons/Group";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CreateIcon from "@material-ui/icons/Create";
import ArrowLeftIcon from "@material-ui/icons/ArrowBackIos";
import ArrowRightIcon from "@material-ui/icons/ArrowForwardIos";
import { default as cloneDeep } from "lodash/cloneDeep";
import ISXUtils, {
  ISXContext,
  DashboardsContext,
  DRAWER_WIDTH,
  APP_BAR_HEIGHT,
} from "services/Utils";
import DashboardLock from "./DashboardLock";

const RELOAD_PAGE_HOUR = 4;

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const defaultProps = {
  className: "layout",
  rowHeight: 30,
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 1 },
  layouts: {},
};

const useStyles = makeStyles((theme) => {
  const transition = theme.transitions.create("margin-left", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  });

  return {
    grid: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingTop: 8,
      paddingLeft: 0,
      paddingBottom: 4,
      paddingRight: 4,
      minHeight: 56,
      transition,
    },
    topBarShift: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: "calc(100% - 44px)",
      },
      transition,
    },
    dividerShift: {
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    dashboardContainer: {
      height: `calc(100dvh - ${APP_BAR_HEIGHT + 57}px)`,
      width: "100%",
      overflow: "auto",
      position: "relative",
      transition,
    },
    dashboardContainerShift: {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "calc(100% - 44px)",
        width: 44,
        overflow: "hidden",
      },
      transition,
    },
    dashboardMask: {
      zIndex: 1000,
      height: "100%",
      width: 44,
      position: "absolute",
    },
    headerButtons: {
      width: 40,
      height: 40,
      padding: 0,
    },
    sharedBadge: {
      color: grey[500],
      width: 14,
    },
  };
});

const Dashboard = (props) => {
  const [title, setTitle] = useState("");
  const [locked, setLocked] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openShareAsTemplateDialog, setOpenShareAsTemplateDialog] =
    useState(false);
  const [openShareDashboardDialog, setOpenShareDashboardDialog] =
    useState(false);

  const titleTimeout = useRef(null);
  const titleInput = useRef(null);
  const refreshTimeout = useRef(null);
  const previousDashboardTitle = useRef(null);

  const isxContext = useContext(ISXContext);
  const { drawerOpen, setDrawerOpen } = useContext(DashboardsContext);

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dashboardTitle = (props.dashboard || {}).title || "";

  const editTitle = () => {
    setAnchorEl(null);
    setTimeout(() => titleInput.current.focus(), 500);
  };

  const checkTime = () => {
    const dd = new Date();
    if (dd.getHours() === RELOAD_PAGE_HOUR) {
      console.log("reload location", dd);
      window.location.reload();
    }
    refreshTimeout.current = setTimeout(checkTime, 3600000);
  };

  useEffect(() => {
    // start a timer to trigger checktimer at the start of next hour.
    //CheckTime will check every hour and when the hour matches the RELOAD_PAGE_HOUR,
    // it will reload the page, which in turn will check for new code updates via serviceworker
    refreshTimeout.current = setTimeout(
      checkTime,
      (60 - new Date().getMinutes()) * 60000
    );
    console.log("set timer on start", refreshTimeout.current);
    return () => {
      clearTimeout(refreshTimeout.current);
    };
  }, []);

  const duplicateDashboard = () => {
    const { title, widgets_layout } = props.dashboard;
    // have to make deep copies of widgets and layout
    const clonedWidgetsLayout = cloneDeep(widgets_layout);
    const clonedWidgets = cloneDeep(widgets);
    const template = {
      title,
      widgets: clonedWidgets,
      widgets_layout: clonedWidgetsLayout,
    };
    const duplicate = ISXUtils.copyDashboardAsTemplate(template);
    props.addDashboard(duplicate);
  };

  useEffect(() => {
    if (props.dashboard && dashboardTitle !== previousDashboardTitle.current) {
      previousDashboardTitle.current = dashboardTitle;
      document.title = `${dashboardTitle || "Unnamed"} | ${
        isxContext.labels.organization
      } Dashboard`;
      setTitle(dashboardTitle);
    }
  }, [props.dashboard, dashboardTitle, isxContext.labels.organization]);

  const handleConfigureMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleConfigureMenuClose = () => {
    setAnchorEl(null);
  };

  const setAsDefault = () => {
    setAnchorEl(null);
    props.setAsDefault();
  };

  const setDashboardTitle = (newTitle) => {
    setTitle(newTitle);
    clearTimeout(titleTimeout.current);
    titleTimeout.current = setTimeout(() => writebackDashboardTitle(), 3000);
  };

  const writebackDashboardTitle = () => {
    clearTimeout(titleTimeout.current);
    if (dashboardTitle !== title) {
      props.setDashboardTitle(title);
      previousDashboardTitle.current = title;
    }
  };

  const onLayoutChange = (layout, layouts) => {
    if (!locked) {
      // when layout gets updated remotely, the dashboard can't distinguish from
      //  a local change; check lock before attempting write
      props.onDashboardChange(layouts);
    }
  };

  const handleConfirmDialog = (event) => {
    setAnchorEl(null);
    setOpenConfirmDialog(true);
  };

  const removeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleShareAsTemplateDialogOpen = (event) => {
    setAnchorEl(null);
    setOpenShareAsTemplateDialog(true);
  };

  const handleShareAsTemplateDialogClose = (event) => {
    setOpenShareAsTemplateDialog(false);
  };

  const handleShareDashboardDialogOpen = (event) => {
    setAnchorEl(null);
    setOpenShareDashboardDialog(true);
  };

  const handleShareDashboardDialogClose = (event) => {
    setOpenShareDashboardDialog(false);
  };

  const {
    isOwner,
    isDashboardAdmin,
    addCard,
    removeWidget,
    openAddWidgetDialog,
    removeAddWidgetDialog,
    // openManageDashboardDialog,
    readOnly = false,
  } = props;
  const classes = useStyles();

  const widgetIds = React.useMemo(
    () => props.dashboard.widgets || [],
    [props.dashboard.widgets]
  );
  const widgets = React.useMemo(
    () => widgetIds.map((guuid) => props.widgets[guuid]),
    [widgetIds, props.widgets]
  );

  const toggleDrawerOpen = () => {
    setDrawerOpen((drawerOpen) => !drawerOpen);
  };

  const renderArrowIcon = (drawerOpen) => {
    // const ArrowIcon = drawerOpen ? ArrowLeftIcon : ArrowRightIcon;
    // return <ArrowIcon style={{ fontSize: "20px" }} />;

    // NOTE: for some reason v4 icons asymmetric so hack to fix for now
    return drawerOpen ? (
      <ArrowLeftIcon
        style={{ fontSize: "20px", marginLeft: 4, marginRight: -4 }}
      />
    ) : (
      <ArrowRightIcon style={{ fontSize: "20px" }} />
    );
  };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [drawerOpen])

  return (
    <div id="dashboard" style={{ overflow: "hidden" }}>
      <Grid
        container
        className={clsx(classes.grid, drawerOpen && classes.topBarShift)}
      >
        <Grid item container xs={8} alignItems="center">
          {!readOnly && (
            <Grid
              item
              style={{ paddingLeft: "0.25rem", paddingRight: "0.125rem" }}
            >
              <IconButton aria-label="Menu" onClick={toggleDrawerOpen}>
                {renderArrowIcon(drawerOpen)}
              </IconButton>
            </Grid>
          )}
          <Grid item style={{ flex: 1 }}>
            {!readOnly && !locked ? (
              <Input
                inputRef={titleInput}
                placeholder="Unnamed Dashboard"
                disableUnderline={true}
                variant="h6"
                color="secondary"
                value={title}
                onChange={(e) => setDashboardTitle(e.target.value)}
                onBlur={writebackDashboardTitle}
                style={{
                  width: "100%",
                }}
              />
            ) : (
              <Typography
                noWrap
                {...(!title && { color: "textSecondary" })}
                style={{
                  fontSize: "1em",
                }}
              >
                {title || "Unnamed Dashboard"}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          {!readOnly && !locked && (
            <IconButton className={classes.headerButtons} onClick={addCard}>
              <AddCircleIcon />
            </IconButton>
          )}
          {!readOnly &&
            (props.dashboard.acu_groups?.length > 0 ? (
              <Badge
                overlap="circular"
                badgeContent={
                  <Tooltip title="Dashboard is shared">
                    <GroupIcon className={classes.sharedBadge} />
                  </Tooltip>
                }
              >
                <DashboardLock
                  guuid={props.dashboard.guuid}
                  locked={locked}
                  setLocked={setLocked}
                ></DashboardLock>
              </Badge>
            ) : (
              <DashboardLock
                guuid={props.dashboard.guuid}
                locked={locked}
                setLocked={setLocked}
              ></DashboardLock>
            ))}
          {!readOnly && (
            <IconButton
              className={classes.headerButtons}
              aria-owns={anchorEl ? "dashboard-configure-menu" : null}
              aria-haspopup="true"
              onClick={handleConfigureMenu}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Box
        className={clsx(
          classes.dashboardContainer,
          drawerOpen && classes.dashboardContainerShift
        )}
      >
        {drawerOpen && isSmallerScreen && (
          <Box className={classes.dashboardMask} />
        )}
        <ResponsiveReactGridLayout
          style={{
            width: drawerOpen ? "100dvw" : "100%",
          }}
          {...defaultProps}
          measureBeforeMount={false}
          onLayoutChange={onLayoutChange}
          layouts={props.widgetsLayout}
          draggableCancel="input,button"
          draggableHandle=".draghandle"
          isDraggable={!readOnly && !locked}
          isResizable={!readOnly && !locked}
        >
          {widgets.map((w) => {
            const widgetType = w.type;
            const widgetContents =
              isxContext.widgets[widgetType] || isxContext.widgets.Unsupported;
            const widgetProps = widgetContents.properties || {};
            const minHeight = widgetProps.minHeight || 8;
            const minWidth = widgetProps.minWidth || 4;
            const defaultHeight = widgetProps.defaultHeight || minHeight;
            const defaultWidth = widgetProps.defaultWidth || minWidth;
            return (
              <div
                key={w.guuid}
                data-grid={{
                  x: 0,
                  y: 0,
                  h: defaultHeight,
                  w: defaultWidth,
                  minH: minHeight,
                  minW: minWidth,
                }}
              >
                <widgetContents.component
                  widget={w}
                  removeWidget={removeWidget}
                  readOnly={readOnly || locked}
                />
              </div>
            );
          })}
        </ResponsiveReactGridLayout>
      </Box>
      <NewWidgetDialog
        open={openAddWidgetDialog}
        guuid={props.guuid}
        removeDialog={removeAddWidgetDialog}
      />
      {/* <ManageDashboardsDialog
        open={openManageDashboardDialog}
        addDashboard={props.addDashboard}
        removeDialog={props.removeManageDashboardDialog}
      /> */}
      {/* <OrgsDashboardsNavDrawer
        open={drawerOpen}
        closeDrawer={closeDrawer}
        addDashboard={props.addDashboard}
      /> */}
      <Menu
        id="dashboard-config-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleConfigureMenuClose}
      >
        <MenuItem onClick={setAsDefault}>
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText inset primary="Set as Default" />
        </MenuItem>
        {!locked && (
          <MenuItem onClick={editTitle}>
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText
              inset
              primary={(title ? "Rename" : "Name") + " this Dashboard"}
            />
          </MenuItem>
        )}
        <MenuItem onClick={duplicateDashboard}>
          <ListItemIcon>
            <DuplicateIcon />
          </ListItemIcon>
          <ListItemText inset primary="Duplicate this Dashboard" />
        </MenuItem>
        <MenuItem onClick={handleShareAsTemplateDialogOpen}>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText inset primary="Share as Template" />
        </MenuItem>
        {!locked && isOwner && (
          <MenuItem onClick={handleShareDashboardDialogOpen}>
            <ListItemIcon>
              <GroupAddIcon />
            </ListItemIcon>
            <ListItemText inset primary="Share this Dashboard" />
          </MenuItem>
        )}
        {!locked && isDashboardAdmin && (
          <MenuItem onClick={handleConfirmDialog}>
            <ListItemIcon>
              <CancelIcon />
            </ListItemIcon>
            <ListItemText inset primary="Delete this Dashboard" />
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={openConfirmDialog}
        onClose={removeConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Are you sure that you want to remove this dashboard?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Press the confirm button to finalize the removal of this dashboard.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.removeDashboard} color="primary" autoFocus>
            Confirm
          </Button>
          <Button onClick={removeConfirmDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ShareTemplateDialog
        dashboard={props.dashboard}
        open={openShareAsTemplateDialog}
        handleClose={handleShareAsTemplateDialogClose}
      />
      <ShareDashboardDialog
        dashboard={props.dashboard}
        open={openShareDashboardDialog}
        handleClose={handleShareDashboardDialogClose}
      />
    </div>
  );
};

export default Dashboard;
