import React from "react";
import Widget from "../../widget/Widget";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import Table from "react-virtualized/dist/commonjs/Table";
import Column from "react-virtualized/dist/commonjs/Table/Column";

function timeLabel(ts) { //stamp to label
  try {
    return new Date(ts).toISOString();
  } catch {
    return ""
  }
}

const ExportLink = ({props}) => {
  const expire = parseFloat(props.expire) ||
                 (parseFloat(props.end_time) + 7*24*3600*1000);
  if(props.url)
    return ((Date.now() > expire) ?
            (<div>Expired</div>) :
            (<a target="_blank" rel="noopener noreferrer" href={props.url}>Click</a>))
  return <div></div>
};

const _rowStyle = ({ index }) => {
  let style = { borderBottom: "1px solid #e0e0e0" };
  if (index < 0) {
    style.textTransform = "none";
  } else if (index % 2 === 0) {
    // won't see border without this
    style.zIndex = 1;
  } else {
    style.backgroundColor = "#fafafa";
  }
  return style;
};

const ExportWidget = (props) => {

  //completed:
  // sort by 'request_time'
  // start_time, end_time
  // status
  // tags[]
  // url
  const sortedData = props.data && props.data.sort((b, a) => a.request_time - b.request_time);

  //pending:
  //options.start: timestamp of start of data range
  //options.end: timestamp of end of data range
  //options.include-errtok: flag to include errtok values (defaults to false)
  //options.include-header: flag to include header in csv
  //options.one-file: flag to combine all tags to a single file
  function updateWidget(opts) {
    function convertDT(dateTimeString) {
      const date = new Date(dateTimeString);
      if (isNaN(date)) {
        throw new Error("Invalid date-time string");
      }
      return Math.floor(date.getTime());
    }
    //convert tag names and start end
    const options = opts.options
    if(options.start) {
      options.start = convertDT(options.start)
      options.end = convertDT(options.end)
    }
    if(options['time-sensitivity']) {
      options['time-sensitivity'] = parseInt(options['time-sensitivity']) || 0;
    }
    const body = {
      tags: opts.tags.map((t) => t.stack+"_"+t.attribute),
      options : options
    }
    props.newRequest(body)    
  }

  //Need some sort of flex container here so table stays in widget
  
  return (
    <Widget {...props} dataType="tags" updateWidget={updateWidget}>
    <div
    style={{ width: "100%", height: "100%" }}
    onClick={(ev) => {
      // stop propagation so this stack remains selected
      ev.stopPropagation();
    }}
    >
    <Card style={{ height: "100%" }}>
    <div><i>Note: Export results are processed as compute is available so they will not appear immediately.</i></div>
    <CardContent style={{ height: "calc(100% - 31px)" }}>
    <div style={{ marginTop: 0, height: "100%" }}>
    { sortedData &&       
      <AutoSizer>
      {({ width, height }) => (
        <Table
        width={width}
        height={height}
        headerHeight={30}
        rowHeight={30}
        rowCount={sortedData.length}
        rowGetter={({ index }) => sortedData[index] || []}
        rowStyle={_rowStyle}
        >
        <Column label="Status" dataKey="status" width={80} flexGrow={0}/>
        <Column label="Description"
        cellRenderer={({rowData}) =>
          rowData.options?.note }
        width={200} flexGrow={0}/>
        <Column label="Tags"
        cellRenderer={({rowData}) =>
          <div title={rowData.tags}> {rowData.tags.length} {rowData.tags.length === 1 ? "tag" : "tags"} </div> }
        width={80} flexGrow={0}/>
        <Column label="Requested"
        cellRenderer={({rowData}) =>
          <div title={rowData.tags}> { timeLabel(rowData.request_time)} tags </div> }
        width={200} flexGrow={0}/>
        <Column label="Download" 
        cellRenderer={({rowData}) => <ExportLink props={rowData}/>}
        width={90} flexGrow={0}/>

        <Column label="Data Count" dataKey="dpcount" width={90} flexGrow={0}/>
        </Table> )}
      </AutoSizer>}
    { !props.data && <div>
      No export jobs. Use settings to create.
                    </div>}
    </div>
    </CardContent>
    </Card>
    </div>
    </Widget>
  );
};

export default ExportWidget;
