import React, { useState, useMemo, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandLessIcon from "@material-ui/icons/ArrowDropDown";
import ExpandMoreIcon from "@material-ui/icons/ArrowRight";
import GroupIcon from "@material-ui/icons/Group";
import DashboardIcon from "@material-ui/icons/ViewCompact";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import FolderIcon from "@material-ui/icons/Folder";
import HelpIcon from "@material-ui/icons/Help";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import SearchIcon from "@material-ui/icons/Search";
import { grey } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";

import _last from "lodash/last";

import ISXUtils, {
  DashboardsContext,
  DRAWER_WIDTH,
  APP_BAR_HEIGHT,
} from "services/Utils";

const DEFAULT_DASHBOARD_TITLE = "UNNAMED DASHBOARD";
const DEFAULT_DASHBOARD_PATH_LABEL = "UNNAMED";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontSize: "0.6875rem",
  },
  ctext: {
    fontWeight: "bold",
    fontSize: "0.6875rem",
  },
  button: {
    fontSize: "0.825rem",
    marginLeft: "0.5rem",
    textTransform: "none",
    marginTop: "1rem",
    paddingTop: 0,
    paddingBottom: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  cancel: {
    color: "red",
  },
  searchItem: {
    display: "flex",
    color: theme.palette.text.secondary,
    paddingLeft: "0.125rem",
  },
  dashboardListDisabled: {
    backgroundColor: theme.palette.text.disabled,
  },
  searchGroupHeader: {
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
    fontWeight: "bolder",
    lineHeight: "1rem",
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    paddingLeft: "1rem",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
  },
  drawer: {
    width: DRAWER_WIDTH,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 44px)",
    },
    marginTop: APP_BAR_HEIGHT,
    height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
    backgroundColor: "#f8f8f8",
    boxShadow: "0.25rem 0 0.5rem rgba(0, 0, 0, 0.125)",
  },
  hideDrawerContainer: {
    paddingTop: 8,
    paddingBottom: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
}));

const DashboardListItem = withStyles((theme) => ({
  root: {
    "&$selected": {
      backgroundColor: alpha(theme.palette.primary.main, 0.4),
    },
    "&$selected:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.6),
    },
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    paddingLeft: "0.5rem",
  },
  selected: {},
}))(ListItem);

const NodeIcon = (props) => {
  const NODE_ICONS_BY_TYPE = {
    folder: FolderIcon,
    "special-folder": FolderSpecialIcon,
    org: GroupIcon,
    dashboard: DashboardIcon,
  };
  const { type, ...rest } = props;
  const NodeTypeIcon = NODE_ICONS_BY_TYPE[type] ?? HelpIcon;
  return <NodeTypeIcon {...rest} />;
};

const _generateNodePath = (node) => {
  const path = [];
  while (node.parent) {
    path.push(node.parent.label);
    node = node.parent;
  }
  path.reverse();
  return path;
};

const NODE_TYPE_SORT_ORDERS = {
  org: 0,
  "special-folder": 1,
  folder: 2,
  dashboard: 3,
};
const _dashboardNodesCompare = (a, b) => {
  const odiff = NODE_TYPE_SORT_ORDERS[a.type] - NODE_TYPE_SORT_ORDERS[b.type];
  return odiff || ISXUtils.strcasecmp(a.label, b.label);
};

const _createDashboardHiearchy = (dashboards, topParent = null) => {
  const _flattenHierarchyNodes = (nodes) => {
    nodes = Object.values(nodes);
    nodes.forEach(
      (node) => (node.subnodes = _flattenHierarchyNodes(node.subnodes ?? {}))
    );
    return nodes.sort(_dashboardNodesCompare);
  };

  let dashboardHierarchy = {};
  dashboards.forEach((dashboard) => {
    let nodes = dashboardHierarchy;
    const title = dashboard.title ?? "";
    const path = title.split(".");
    let parent = null;
    path.slice(0, -1).forEach((el, idx) => {
      // trim leading and trailing whitespace, and replace all
      // remaining multiple whitespace with single space
      const label =
        el.trim().replace(/\s+/g, " ") || DEFAULT_DASHBOARD_PATH_LABEL;
      const id = `${topParent?.id ?? ""}.${path.slice(0, idx + 1).join(".")}`;
      let next = nodes[id];
      if (!next) {
        next = nodes[id] = {
          id,
          label,
          type: "folder",
          subnodes: {},
          parent,
        };
      }
      parent = next;
      nodes = next.subnodes;
    });
    const id = `${topParent?.id ?? ""}.${path.slice(0, -1).join(".")}.${
      dashboard.guuid
    }`;
    nodes[id] = {
      id,
      label: _last(path) || DEFAULT_DASHBOARD_TITLE,
      type: "dashboard",
      entity: dashboard,
      parent,
    };
  });
  dashboardHierarchy = _flattenHierarchyNodes(dashboardHierarchy);
  dashboardHierarchy.forEach((subnode) => (subnode.parent = topParent));
  return dashboardHierarchy;
};

const SEARCH_OPTION_SORT_ORDERS = {
  org: 0,
  "special-folder": 1,
  folder: 2,
  dashboard: 3,
};

const _dashboardSearchOptionsCompare = (a, b) => {
  const tdiff =
    SEARCH_OPTION_SORT_ORDERS[a.type] - SEARCH_OPTION_SORT_ORDERS[b.type];
  if (tdiff === 0) {
    return ISXUtils.strcasecmp(a.label, b.label);
  } else {
    return tdiff;
  }
};

const DashboardsNestedList = ({ node, showCounts, closeDrawer, disabled }) => {
  const {
    drawerExpanded: expanded,
    setDrawerExpanded: setExpanded,
    drawerSelected: selected,
    setDrawerSelected,
  } = useContext(DashboardsContext);
  const classes = useStyles();

  const toggle = () => {
    setExpanded((expanded) => {
      const newExpanded = new Set(expanded);
      if (newExpanded.has(node.id)) {
        newExpanded.delete(node.id);
      } else {
        newExpanded.add(node.id);
      }
      return newExpanded;
    });
  };

  const setSelected = () => {
    return setDrawerSelected(new Set([node.id]));
  };

  const _getNodeLabel = (node) => {
    let label = node.label;
    if (showCounts && node.type !== "dashboard") {
      label += ` (${node.count || 0})`;
    }
    return label;
  };

  const open = expanded.has(node.id);

  return (
    <>
      <DashboardListItem
        dense
        button
        onClick={() => {
          setSelected();
          node.subnodes?.length > 0 && toggle();
        }}
        selected={selected.has(node.id)}
        disabled={disabled}
        classes={{
          root: classes.itemSelected,
        }}
      >
        <Box
          component={"span"}
          style={{
            display: "flex",
            color: "rgba(0, 0, 0, 0.54)",
            opacity: node.subnodes?.length > 0 ? 1 : 0,
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        <ListItemIcon>
          <NodeIcon type={node.type} fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={_getNodeLabel(node)}
          classes={{
            primary: classes.ctext,
          }}
        />
      </DashboardListItem>
      <Collapse
        in={open}
        mountOnEnter={true}
        timeout="auto"
        className={classes.nested}
      >
        <DashboardsList
          nodes={node.subnodes}
          showCounts={showCounts}
          closeDrawer={closeDrawer}
        />
      </Collapse>
    </>
  );
};

const DashboardsList = ({ nodes, showCounts, closeDrawer, disabled }) => {
  const { drawerSelected: selected, setDrawerSelected: setSelected } =
    useContext(DashboardsContext);

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  return (
    <List style={{ paddingTop: 0, paddingBottom: 0 }}>
      {nodes.map((cnode) => {
        if (
          cnode.type === "folder" ||
          cnode.type === "special-folder" ||
          cnode.type === "org"
        ) {
          return (
            <DashboardsNestedList
              key={cnode.id}
              node={cnode}
              showCounts={showCounts}
              closeDrawer={closeDrawer}
              disabled={disabled}
            />
          );
        } else {
          const dashboard = cnode.entity;
          return (
            <DashboardListItem
              key={dashboard.guuid}
              dense
              button={true}
              component={Link}
              to={"/dashboard/" + dashboard.guuid}
              selected={selected.has(cnode.id)}
              onClick={() => {
                setSelected(new Set([cnode.id]));
                if (isSmallerScreen) {
                  closeDrawer();
                }
              }}
              disabled={disabled}
            >
              <ListItemIcon>
                <NodeIcon type={cnode.type} fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={cnode.label}
                classes={{
                  primary: classes.text,
                }}
              />
            </DashboardListItem>
          );
        }
      })}
    </List>
  );
};

const GroupDashboardSearch = (props) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [labelText, setLabelText] = useState();
  const navigate = useNavigate();

  const { options, setOpen, setExpanded, setSelected, closeDrawer, label } =
    props;

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  useEffect(() => {
    setLabelText(label);
  }, [label]);

  return (
    <Box height={56} display="flex" alignItems="center">
      <Autocomplete
        fullWidth
        disableClearable
        popupIcon={null}
        size="small"
        blurOnSelect
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        getOptionLabel={(option) => option.label}
        groupBy={(option) =>
          option.type === "dashboard" ? "Dashboards" : "Groups"
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderGroup={(params) => {
          return [
            <ListSubheader
              key={params.key}
              className={classes.searchGroupHeader}
            >
              {params.group}
            </ListSubheader>,
            params.children,
          ];
        }}
        renderOption={(option) => {
          return (
            <Box className={classes.searchItem}>
              <NodeIcon
                type={option.type}
                style={{ fontSize: "1rem", marginRight: "0.5rem" }}
              />
              <Typography
                component={"span"}
                style={{
                  fontSize: "0.6875rem",
                  fontWeight: option.type === "dashboard" ? 400 : 700,
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              >
                {option.label}
                {option.path?.length > 0 && option.dcount > 1 && (
                  <span style={{ color: grey[400], fontStyle: "italic" }}>
                    {" ("}
                    {option.path.map((part, index) => (
                      <React.Fragment key={`${part}-${index}`}>
                        {part}
                        {index < option.path.length - 1 && (
                          <ArrowForwardIcon
                            style={{
                              fontSize: "0.5rem",
                              paddingLeft: "0.125rem",
                              paddingRight: "0.125rem",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                    {")"}
                  </span>
                )}
              </Typography>
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={() => setLabelText(`Searching ${label}...`)}
            onBlur={() => setLabelText(label)}
            label={
              <Box display="flex" alignItems="center" gridColumnGap="0.5rem">
                <SearchIcon style={{ fontSize: "1.25rem" }} />
                {labelText}
                {/* Search for Group/Dashboard... */}
              </Box>
            }
            variant="filled"
            margin="none"
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { backgroundColor: "inherit" },
            }}
            InputLabelProps={{
              style: {
                fontSize: "1rem",
              },
            }}
          />
        )}
        onChange={(_, option) => {
          if (option) {
            const newExpanded = new Set([option.id]);
            let cur = option;
            while (cur.parent) {
              newExpanded.add(cur.parent.id);
              cur = cur.parent;
            }
            setExpanded(newExpanded);
            setSelected(new Set([option.id]));
            setValue(null);
            setInputValue("");
            if (isSmallerScreen) {
              closeDrawer();
            }
            if (option.type === "dashboard") {
              navigate("/dashboard/" + option.entity.guuid);
            }
          }
        }}
      />
    </Box>
  );
};

const GroupsDashboardsNavDrawer = (props) => {
  const [searchOpen, setSearchOpen] = useState(false);

  const {
    setDrawerExpanded: setExpanded,
    setDrawerSelected: setSelected,
    drawerOpen: open,
    // drawerOpen,
    setDrawerOpen: setOpen,
    addDashboard,
  } = useContext(DashboardsContext);

  const classes = useStyles();

  const user = props.user;

  const { dashboardSearchOptions, smartFolders, orgHierarchy, label } =
    useMemo(() => {
      // avoid computation if dialog not open
      if (!open) {
        return {
          dashboardSearchOptions: [],
          smartFolders: [],
          orgHierarchy: [],
          label: null,
        };
      }

      // get personal dashboards (owned by this user, not shared with any group
      // user is member of)
      const candidates = user.dashboards.map(
        (guuid) => props.dashboards[guuid]
      );
      const user_orgs = new Set(user.orgs.map((org) => org.orgid));
      const personal = candidates.filter(
        (dashboard) =>
          dashboard &&
          !dashboard.acu_groups?.filter((org) => user_orgs.has(org))
      );

      const visibleOnlyToMeFolder = {
        id: "sf-personal",
        label: "Visible Only to Me",
        type: "special-folder",
        parent: null,
      };
      const personalDashboardHierarchy = _createDashboardHiearchy(
        personal,
        visibleOnlyToMeFolder
      );
      visibleOnlyToMeFolder.subnodes = personalDashboardHierarchy;
      const smartFolders = [visibleOnlyToMeFolder];

      const personalIds = new Set(personal.map((dashboard) => dashboard.guuid));

      let orgHierarchy = {};
      const _buildOrgNode = (org, parent, seen) => {
        if (seen.has(org.guuid)) {
          console.warn("cycle");
          return null;
        }
        seen.add(org.guuid);
        let node = orgHierarchy[org.guuid];
        if (node) {
          node.root &&= !parent;
          if (parent && !node.parent) {
            node.parent = orgHierarchy[parent];
          }
        } else {
          node = {
            id: `org-${org.guuid}`,
            label: org.name,
            type: "org",
            entity: org,
            root: !parent,
            parent: parent ? orgHierarchy[parent] : null,
          };
          orgHierarchy[org.guuid] = node;
          const suborgs =
            org.orgs
              ?.map((suborgId) => {
                const suborg = props.orgs[suborgId];
                return suborg && _buildOrgNode(suborg, org.guuid, seen);
              })
              .filter((subnode) => !!subnode) ?? [];
          const dashboards =
            org.dashboards
              ?.filter((dashboardId) => !personalIds.has(dashboardId))
              .map((dashboardId) => props.dashboards[dashboardId])
              .filter((dashboard) => !!dashboard) ?? [];
          const dashboardHierarchy = _createDashboardHiearchy(dashboards, node);
          node.subnodes = [
            ...suborgs.sort(_dashboardNodesCompare),
            ...dashboardHierarchy,
          ];
          seen.delete(org.guuid);
        }
        return node;
      };
      Object.values(props.orgs).forEach((org) => {
        _buildOrgNode(org, null, new Set());
      });
      orgHierarchy = Object.values(orgHierarchy)
        .filter((entry) => entry.root)
        .sort(_dashboardNodesCompare);


      let label = null;
      if (orgHierarchy.length === 1) {
        label = orgHierarchy[0].label ?? "My Organization";
        orgHierarchy = orgHierarchy[0].subnodes;
        orgHierarchy.forEach((node) => (node.parent = null));
      }

      const _getDashboardCounts = (node) => {
        if (node.type === "dashboard") {
          // don't need to store count on dashboard node
          return 1;
        } else {
          let count = 0;
          node.subnodes?.forEach((subnode) => {
            count += _getDashboardCounts(subnode);
          });
          node.count = count;
          return count;
        }
      };

      orgHierarchy.forEach((org) => {
        _getDashboardCounts(org, null, new Set());
      });

      const dashboardSearchOptions = [];
      const _generateSearchOptions2 = (nodes) => {
        nodes.forEach((node) => {
          dashboardSearchOptions.push(node);
          _generateSearchOptions2(node.subnodes);
        });
      };
      _generateSearchOptions2(personalDashboardHierarchy);
      _generateSearchOptions2(orgHierarchy);
      dashboardSearchOptions.sort(_dashboardSearchOptionsCompare);

      dashboardSearchOptions.forEach(
        (option) => (option.path = _generateNodePath(option))
      );
      const duplicateCounts = {};
      dashboardSearchOptions.forEach((option) => {
        const key = `${option.type}|${option.label}`;
        duplicateCounts[key] = (duplicateCounts[key] ?? 0) + 1;
      });
      dashboardSearchOptions.forEach((option) => {
        const key = `${option.type}|${option.label}`;
        option.dcount = duplicateCounts[key];
      });
      // console.log({
      //   dashboardSearchOptions,
      //   smartFolders,
      //   orgHierarchy,
      //   label,
      // });
      return {
        dashboardSearchOptions,
        smartFolders,
        orgHierarchy,
        label,
      };
    }, [props.dashboards, open, props.orgs, user.dashboards, user.orgs]);

  // avoid overhead of render if not going to be visible
  // if (!open) {
  //   return null;
  // }

  const closeDrawer = () => setOpen(false);

  return (
    <Drawer
      PaperProps={{
        className: classes.drawer,
      }}
      variant="persistent"
      anchor="left"
      open={open}
      onClose={closeDrawer}
    >
      <Box overflow="auto" display="flex" flexDirection="column" height="100%">
        <Box>
          <GroupDashboardSearch
            options={dashboardSearchOptions}
            setOpen={setSearchOpen}
            setExpanded={setExpanded}
            setSelected={setSelected}
            closeDrawer={closeDrawer}
            label={label ?? "My Organizations"}
          />
          <Divider />
          <Button
            // variant="contained"
            color="primary"
            onClick={() => {
              addDashboard();
              closeDrawer();
            }}
            className={classes.button}
          >
            + Create new dashboard
          </Button>
        </Box>
        <Box
          paddingBottom={1}
          // paddingLeft={1}
          paddingRight={1}
          flexShrink={0}
          overflow="auto"
          maxHeight="35%"
        >
          <List>
            <DashboardsList
              nodes={smartFolders}
              closeDrawer={closeDrawer}
              disabled={searchOpen}
            />
          </List>
        </Box>
        <Divider />
        <Box overflow="auto">
          {/* <DashboardTreeView nodes={orgHierarchy} /> */}
          <DashboardsList
            nodes={orgHierarchy}
            showCounts
            closeDrawer={closeDrawer}
            disabled={searchOpen}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default GroupsDashboardsNavDrawer;
