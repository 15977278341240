import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GroupIcon from "@material-ui/icons/Group";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";

import last from "lodash/last";

import ComboBox from "components/ComboBox";
import ISXUtils from "services/Utils";

const DEFAULT_DASHBOARD_TITLE = "UNNAMED DASHBOARD";
const DEFAULT_DASHBOARD_PATH_LABEL = "UNNAMED";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontSize: "0.875rem",
  },
  ctext: {
    fontWeight: "bold",
    fontSize: "0.875rem",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  cancel: {
    color: "red",
  },
  searchItem: {
    display: "flex",
    color: theme.palette.text.secondary,
    paddingLeft: "1em",
  },
  dashboardListDisabled: {
    backgroundColor: theme.palette.text.disabled,
  },
}));

const DashboardsNestedList = ({ node, removeDialog, disabled }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const toggle = () => setOpen(!open);

  return (
    <>
      <ListItem divider button onClick={toggle} disabled={disabled}>
        <ListItemText
          primary={node.label}
          classes={{
            primary: classes.ctext,
          }}
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" className={classes.nested}>
        <DashboardsList nodes={node.children} removeDialog={removeDialog} />
      </Collapse>
    </>
  );
};

const DashboardsList = ({ nodes, removeDialog, disabled }) => {
  const classes = useStyles();
  return (
    <List>
      {Object.values(nodes)
        .sort((a, b) => (a.label < b.label ? -1 : 1))
        .map((cnode) => {
          if (cnode.type === "inner") {
            return (
              <DashboardsNestedList
                key={`$row-${cnode.label}`}
                node={cnode}
                removeDialog={removeDialog}
                disabled={disabled}
              />
            );
          } else {
            const dashboard = cnode.dashboard;
            return (
              <ListItem
                key={`$row-${cnode.dashboard.guuid}`}
                divider
                button={true}
                component={Link}
                onClick={(_) => {
                  removeDialog();
                }}
                to={"/dashboard/" + dashboard.guuid}
                disabled={disabled}
              >
                {cnode.shared && (
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={cnode.label}
                  classes={{
                    primary: classes.text,
                  }}
                />
              </ListItem>
            );
          }
        })}
    </List>
  );
};

const ManageDashboardsDialog = (props) => {
  const [searchOpen, setSearchOpen] = useState(false);

  const classes = useStyles();

  const user = props.user;

  const { dashboardHierarchy, dashboardSearchOptions } = useMemo(() => {
    // avoid computation if dialog not open
    if (!props.open) {
      return { dashboardHierarchy: {}, dashboardSearchOptions: [] };
    }

    const dashboardHierarchy = {};
    let dashboardSearchOptions = [];
    const dashboards = Object.values(props.dashboards ?? {});
    dashboards.forEach((dashboard) => {
      let nodes = dashboardHierarchy;
      // convert to all uppercase for case-insensitivity and display
      const title = (dashboard.title || "").toUpperCase();
      const path = title.split(".");
      path.slice(0, -1).forEach((el) => {
        // trim leading and trailing whitespace, and replace all
        // remaining multiple whitespace with single space
        const label =
          el.trim().replace(/\s+/g, " ") || DEFAULT_DASHBOARD_PATH_LABEL;
        const key = `i-${label}`;
        let next = nodes[key];
        if (!next) {
          next = nodes[key] = { label, type: "inner", children: {} };
        }
        nodes = next.children;
      });
      dashboardSearchOptions.push({
        title: path.join(" "),
        org: path.slice(0, -1).join("."),
        name: last(path),
        shared: user.userid !== dashboard.g_owner,
        dashboard,
      });
      nodes[`l-${dashboard.guuid}`] = {
        label: last(path) || DEFAULT_DASHBOARD_TITLE,
        type: "leaf",
        shared: user.userid !== dashboard.g_owner,
        dashboard,
      };
    });
    dashboardSearchOptions = dashboardSearchOptions.sort(
      (a, b) =>
        ISXUtils.strcasecmp(a.org, b.org) || ISXUtils.strcasecmp(a.name, b.name)
    );
    return { dashboardHierarchy, dashboardSearchOptions };
  }, [props.dashboards, props.open, user.userid]);

  // avoid overhead of render if not going to be visible
  if (!props.open) {
    return null;
  }

  return (
    <Dialog fullWidth={true} maxWidth="md" open={props.open}>
      <DialogTitle>
        Select Dashboard
        {props.removeDialog && (
          <IconButton onClick={props.removeDialog} className={classes.close}>
            <ClearIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        dividers
        className={searchOpen ? classes.dashboardListDisabled : ""}
      >
        <DashboardsList
          nodes={dashboardHierarchy}
          removeDialog={props.removeDialog}
          disabled={searchOpen}
        />
      </DialogContent>
      <DialogActions>
        <Box
          width="100%"
          marginLeft={2}
          marginRight={2}
          display="flex"
          flexDirection="column"
        >
          <Box>
            <ComboBox
              label="Search for Dashboard..."
              options={dashboardSearchOptions}
              getOptionLabel={(option) => option.title}
              groupBy={(option) => option.org}
              onOpen={() => setSearchOpen(true)}
              onClose={() => setSearchOpen(false)}
              renderGroup={(params) => {
                const gparts = params.group.split(".");
                return [
                  <ListSubheader key={params.key} disableSticky>
                    {gparts.map((part, index) => (
                      <Box
                        key={`${params.key}-${index}`}
                        display="inline-flex"
                        alignItems="center"
                      >
                        {part}
                        {index < gparts.length - 1 && (
                          <ArrowForwardIcon
                            style={{
                              fontSize: "0.75rem",
                              paddingLeft: "0.125rem",
                              paddingRight: "0.125rem",
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </ListSubheader>,
                  params.children,
                ];
              }}
              renderOption={(option) => (
                <Box className={classes.searchItem}>
                  {option.shared && (
                    <GroupIcon style={{ marginRight: "1rem" }} />
                  )}
                  <Typography>{option.name}</Typography>
                </Box>
              )}
              onChange={(_, option) => {
                props.removeDialog();
                window.location.hash = "#/dashboard/" + option.dashboard.guuid;
              }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            marginTop={1}
            style={{ gap: "0.5rem" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                props.addDashboard();
                props.removeDialog();
              }}
            >
              Add a new dashboard
            </Button>
            {props.removeDialog && (
              <Button onClick={props.removeDialog} style={{ color: "red" }}>
                Cancel
              </Button>
            )}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ManageDashboardsDialog;
