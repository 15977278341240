import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import QueryService from "services/QueryService"; //TEMP FOR TESTING

const Profile = (props) => {
  const [code, setCode] = useState("");
  const [codeE, setCodeE] = useState("");

  const showVerifyPhone=
        props.profile.phone && props.mode !== "changed" && props.profile.verified_phone != props.profile.phone;
  //note: we don't use this at the moment since we don't yet allow email edit
  const showVerifyEmail=
        props.profile.verified_email !== props.profile.email || props.profile.new_email_addr;
  const showVerifiedEmail = props.profile.email && props.profile.email === props.profile.verified_email && props.mode === 'edit';
  const showVerifiedPhone = props.profile.phone && props.profile.phone === props.profile.verified_phone && props.mode === 'edit';

  const warning = props.profile.phone && (!props.profile.phone.startsWith("+") || props.profile.phone.length < 12);
  
  return (
    <Grid
      container
      justifyContent="center"
      style={{ width: "100%", padding: 20, paddingTop: 56, flex: 1 }}
    >
      <Grid item sm={12} md={6} lg={4} style={{ width: "100%" }}>
        <Grid container spacing={3} align="left" style={{marginBottom: 4}}>
          <Grid item xs={12}>
            <Typography variant="h6">User Profile</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              value={props.profile.email}
              disabled
            />
            { !!showVerifiedEmail && <div style={{marginTop: 8}}>Verified</div>}
          </Grid>
          {false && !!props.showVerifyEmail && (
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <TextField
                  label="Email Code Received"
                  value={codeE}
                  onChange={(e) => setCodeE(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => props.verifyEmail(codeE)}
                >
                  Verify Email
                </Button>
              </div>
            </Grid>
          )}
          {false && ( //later
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Change Email"
                disabled
                value={props.profile.new_email_addr}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              value={props.profile.name}
              onChange={(e) => props.changeProp("name", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Multifactor Delivery</InputLabel>
            <Select
              value={props.profile.mfa ?? ""}
              onChange={(e) => props.changeProp("mfa", e.target.value)}
              displayEmpty
            >
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="sms">SMS</MenuItem>
              <MenuItem value="">--disabled--</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="SMS Compatible Phone"
              value={props.profile.phone}
              onChange={(e) => props.changeProp("phone", e.target.value)}
            />
            { !!showVerifiedPhone && <div style={{marginTop: 8}}>Verified</div> }
          </Grid>
          {showVerifyPhone && (
            <Grid item xs={12}>
            { !!warning && <div style={{marginTop: 8}}>Phone number must have a country code with a plus sign to work for SMS.  Use +1 for US phones.  Make sure complete phone with all area codes is specified.</div> }
              <div style={{ display: "flex", justifyContent: "end", columnGap: 16 }}>
                <TextField
                  label="SMS Code Received"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  // style={{marginRight: 16}}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => props.verifyPhone(code)}
                >
                  Verify Phone
                </Button>
              </div>
            </Grid>
          )}
          {props.error && (
            <Grid item xs={12}>
              <Typography color="error" align="right">
                {props.error}
              </Typography>
            </Grid>
          )}
        </Grid>
        <div style={{ display: "flex", justifyContent: "end" }}>
          {props.mode === "changed" && (
            <Button variant="contained" color="primary" onClick={props.save}>
              Save
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={props.close}
            style={{ marginLeft: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(a) => {
              QueryService.getExports().then(a => {
                console.log(a)})                
            }}
            style={{ marginLeft: "1rem" }}
          >
            TEST EXPORT
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(a) => {
              QueryService.putExport({ tags: ['~01039784187b649021bc8b3aef8a376042_co2']}).then(a => {
                console.log(a)})
            }}
            style={{ marginLeft: "1rem" }}
          >
            TEST EXPORT REQUEST
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default Profile;
