import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import Tooltip from "@material-ui/core/Tooltip";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { blue } from "@material-ui/core/colors";

import WidgetConfigureDialog from "../WidgetConfigureDialog";
import AddNewTagDialog from "./AddNewTagDialog";
import TagConfigDialog from "components/TagConfig/TagConfigDialog";
import ComboBox from "components/ComboBox";
import ISXUtils, { ISXContext } from "services/Utils";

import _ from "lodash";

const uuidv4 = require("uuid/v4");

const USE_TAG_CONFIG = true;
const derivedTagSeparator = "|";
const localComputeIndicator = "-lc";

const getOriginalTag = (tag) => {
  const tparts = tag.split(derivedTagSeparator);
  if (
    tparts.length > 1 &&
    tparts[tparts.length - 1].endsWith(localComputeIndicator)
  ) {
    return tparts.slice(0, -1).join(derivedTagSeparator);
  }
  return tag;
};

const filterTag = (tag, filterTagsOptions, stack) => {
  const _filterByMatchPrefixes = () => {
    const tagsMatchPrefixes = filterTagsOptions?.matchPrefixes;
    return tagsMatchPrefixes
      ? tagsMatchPrefixes.some((prefix) => tag.startsWith(prefix))
      : true;
  };
  const _filterByMatchTypes = () => {
    const tagMatchTypes = filterTagsOptions?.matchTypes;
    if (!tagMatchTypes) {
      return true;
    }
    const tagType = stack?.data_config?.[tag]?.isx_tag_type;
    return tagMatchTypes.includes(tagType);
  };
  return (
    !tag.endsWith("_aggcount") &&
    _filterByMatchPrefixes() &&
    _filterByMatchTypes()
  );
};

const useStyles = makeStyles((theme) => ({
  openIcon: {
    color: blue[500],
    cursor: "pointer",
  },
  movingAverageHeader: {
    fontSize: "0.75rem",
    fontWeight: "bolder",
    lineHeight: "1rem",
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    paddingLeft: "1rem",
    borderBottom: `2px solid`,
    backgroundColor: theme.palette.background.paper,
  },
}));

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    borderWidth: 2,
    borderStyle: "solid",
    border: theme.palette.divider,
  },
}))(Tooltip);

const TagTableCell = withStyles((theme) => ({
  head: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  body: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}))(TableCell);

const TagAttributeCell = (props) => {
  const [addTagDialogOpen, setAddTagDialogOpen] = useState(false);

  const classes = useStyles();

  const derivedTagSeparator = "|";
  const localComputeIndicator = "lc";
  const movingAverage = "ma";
  const windowSpec = "10";
  const stackId = props.stack;
  const stack = props.stacks[stackId];
  const attributes = useMemo(
    () =>
      stack
        ? ((stack.data_config || {}).dsitems || []).filter((ds) =>
            filterTag(ds, props.filterTagsOptions, stack)
          )
        : [],
    [props.filterTagsOptions, stack]
  );

  const tagInfo = useMemo(() => {
    const tagInfo = Object.fromEntries(
      attributes.map((attr) => [
        attr,
        { name: ISXUtils.getTagName(attr, stack.data_config), avg: false },
      ])
    );
    if (props.showDerivedTags !== false) {
      attributes.forEach((attr) => {
        const derivedTag =
          derivedTagSeparator +
          movingAverage +
          "-" +
          (
            stack["data_config"][attr]?.numeric?.ema?.window ||
            parseInt(windowSpec)
          ).toString() +
          "sm-" +
          localComputeIndicator;
        tagInfo[attr + derivedTag] = {
          name: tagInfo[attr].name + " (Avg)",
          avg: true,
        };
      });
    }
    return tagInfo;
  }, [attributes, props.showDerivedTags, stack]);

  const tagNames = useMemo(
    () =>
      Object.entries(tagInfo)
        .sort((a, b) =>
          a[1].avg < b[1].avg
            ? -1
            : a[1].avg > b[1].avg
            ? 1
            : ISXUtils.strcasecmp(a[1].name, b[1].name)
        )
        .map((a) => a[0]),
    [tagInfo]
  );

  const handleCloseAddTagDialog = () => setAddTagDialogOpen(false);

  return (
    <>
      <TagTableCell>
        {attributes.length !== 1 || props.allowCreateNewTag ? (
          <ComboBox
            value={props.attribute}
            options={tagNames}
            onChange={(_, attr) => props.setAttribute(attr)}
            getOptionLabel={(attr) => (attr ? tagInfo[attr].name : "")}
            groupBy={(attr) => tagInfo[attr].avg}
            renderGroup={(params) => [
              params.group ? (
                <ListSubheader
                  key={params.key}
                  className={classes.movingAverageHeader}
                >
                  Moving Average (10 Samples)
                </ListSubheader>
              ) : (
                params.group
              ),
              params.children,
            ]}
          />
        ) : (
          <Typography>{tagNames[0]}</Typography>
        )}
      </TagTableCell>
      {props.allowCreateNewTag && addTagDialogOpen && (
        <AddNewTagDialog
          tagType={props.attributeColumnHeader || "Tag"}
          open={addTagDialogOpen}
          onClose={handleCloseAddTagDialog}
          stackId={props.stack}
          callback={props.setAttribute}
        />
      )}
    </>
  );
};

const TagRow = (props) => {
  const [tagDialogOpen, setTagDialogOpen] = useState(false);

  const { stacksSortedByName } = props;

  const classes = useStyles();

  const tagDefined = () => {
    return props.stack && props.attribute;
  };

  const setStack = (_, stack) => {
    const attributes = stack
      ? ((stack.data_config || {}).dsitems || []).filter((ds) =>
          filterTag(ds, props.filterTagsOptions, stack)
        )
      : [];
    const attr = attributes.length === 1 ? attributes[0] : "";
    props.setTagProps(props.guuid, {
      stack: stack.guuid,
      attribute: attr,
    });
  };

  const setAttribute = (attribute) => {
    if (attribute) {
      props.setTagProps(props.guuid, { attribute });
    }
  };

  return (
    <TableRow>
      <TagTableCell>
        {stacksSortedByName.length !== 1 ? (
          <ComboBox
            value={props.stacks[props.stack]}
            options={stacksSortedByName}
            onChange={setStack}
            getOptionLabel={ISXUtils.getStackName}
          />
        ) : (
          <Typography>
            {ISXUtils.getStackName(stacksSortedByName[0])}
          </Typography>
        )}
      </TagTableCell>
      <TagAttributeCell
        {...props}
        setAttribute={setAttribute}
        setAddTagDialogOpen={props.setAddTagDialogOpen}
      />
      <TableCell style={{ paddingLeft: 0, paddingRight: 0 }}>
        {USE_TAG_CONFIG && props.stack && props.attribute && (
          <InfoTooltip
            title="Click to view/modify tag properties"
            onClick={(e) => {
              setTagDialogOpen(true);
            }}
            disabled={!props.stack || !props.attribute}
          >
            <OpenInNewIcon fontSize="small" className={classes.openIcon} />
          </InfoTooltip>
        )}
      </TableCell>
      <TagTableCell>
        {props.deleteTag && (
          <IconButton onClick={() => props.deleteTag(props.guuid)}>
            <CancelIcon />
          </IconButton>
        )}
        {props.addTag && (
          <IconButton onClick={props.addTag} disabled={!tagDefined()}>
            <AddCircleIcon />
          </IconButton>
        )}
      </TagTableCell>
      {tagDialogOpen && (
        <TagConfigDialog
          open={tagDialogOpen}
          setOpen={setTagDialogOpen}
          tag={{
            guuid: props.guuid,
            stack: props.stack,
            attribute: getOriginalTag(props.attribute),
          }}
        />
      )}
    </TableRow>
  );
};

const WidgetTagsData = (props) => {
  const isxContext = useContext(ISXContext);

  const { maxTags, constrainTagStack } = props;
  const tags = Object.values(props.tags);

  const stacksSortedByName = useMemo(
    () =>
      props.stacks
        ? (props.allowCreateNewTag
            ? Object.values(props.stacks)
            : Object.values(props.stacks).filter((stack) =>
                ((stack.data_config || {}).dsitems || []).some((ds) =>
                  filterTag(ds, props.filterTagsOptions, stack)
                )
              )
          ).sort((a, b) =>
            ISXUtils.strcasecmp(
              ISXUtils.getStackName(a),
              ISXUtils.getStackName(b)
            )
          )
        : [],
    [props.allowCreateNewTag, props.filterTagsOptions, props.stacks]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TagTableCell>{isxContext.labels.deviceCapitalized}</TagTableCell>
          <TagTableCell>
            {props.attributeColumnHeader ?? "Attribute"}
          </TagTableCell>
          <TagTableCell />
          <TagTableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {tags.map((tag, idx) => {
          const addTag =
            idx === tags.length - 1 && (!maxTags || tags.length < maxTags)
              ? props.addTag
              : null;
          const deleteTag = maxTags !== 1 ? props.deleteTag : null;
          let stacks = null;
          if (constrainTagStack && tags.length > 1) {
            let tstacks = tags.map((t) => t.stack).filter((t) => !!t);
            tstacks = new Set(tstacks);
            if (tstacks.size === 1) {
              const stid = tstacks.values().next().value;
              const st = (props.stacks || {})[stid];
              stacks = st && { [stid]: st };
            }
          }
          if (!stacks) {
            stacks = props.stacks || {};
          }
          return (
            <TagRow
              {...props}
              key={tag.guuid}
              stacks={stacks}
              stacksSortedByName={stacksSortedByName}
              {...tag}
              deleteTag={deleteTag}
              addTag={addTag}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

const WidgetTagsConfigureDialog = (props) => {
  const [tags, setTags] = useState();
  const [initialized, setInitialized] = useState(false);
  const dirty = useRef(false);

  const createEmptyTag = useCallback(() => {
    const guuid = uuidv4();
    const stacks = !!props.stacks ? Object.values(props.stacks) : [];
    let stack = stacks.length === 1 ? stacks[0].guuid : "";
    if (!stack && (props.prepopulateTagStack || props.constrainTagStack)) {
      let tstacks = Object.values(tags || {})
        .map((t) => t.stack)
        .filter((t) => !!t);
      tstacks = new Set(tstacks);
      if (tstacks.size === 1) {
        stack = tstacks.values().next().value;
      }
    }
    const attrs =
      stacks.length === 1
        ? ((stacks[0].data_config || {}).dsitems || []).filter((ds) =>
            filterTag(ds, props.filterTagsOptions, stack)
          )
        : [];
    const attr = attrs.length === 1 ? attrs[0] : "";
    return {
      guuid: guuid,
      stack: stack,
      attribute: attr,
    };
  }, [
    props.constrainTagStack,
    props.filterTagsOptions,
    props.prepopulateTagStack,
    props.stacks,
    tags,
  ]);

  useEffect(() => {
    if (!initialized) {
      const widget = props.widget;
      const tags = widget.tags || [];
      let tagsMap = {};
      if (tags.length > 0) {
        tags.forEach((tag) => {
          tagsMap[tag.guuid] = tag;
        });
      } else {
        const tag = createEmptyTag();
        tagsMap[tag.guuid] = tag;
      }
      setTags(tagsMap);
      setInitialized(true);
    }
  }, [initialized, props.widget, createEmptyTag]);

  const prepareTagsForWriteback = () => {
    let tagsList = Object.values(tags);
    if (tagsList.length > 0 && !tagDefined(_.last(tagsList))) {
      tagsList.pop();
    }
    return { tags: tagsList };
  };

  const titleBuilder = (data) => {
    let allStacks = new Set();
    data.tags.forEach((tag) => {
      allStacks.add(tag.stack);
    });
    const allAttributes = data.tags.map((tag) => tag.attribute);
    let title;
    if (allStacks.size === 1) {
      const stackid = allStacks.values().next().value;
      const stack = props.stacks[stackid] || {};
      const stackTitle =
        stack?.name || JSON.parse(stack.dev_session || "{}")["stack.label"];
      const attrs = allAttributes
        .map((attr) => ISXUtils.getTagDisplayText(attr, stack.data_config))
        .join(", ");
      title = stackTitle + " - " + attrs;
    } else {
      title = "Widget";
    }
    return title;
  };

  const areTagsValid = () => {
    const tagsList = Object.values(tags);
    return tagsList.length > 0 && tagDefined(_.last(tagsList));
  };

  const setTagProps = (guuid, props) => {
    let tag = tags[guuid];
    if (tag) {
      tag = {
        ...tag,
        ...props,
      };
      setTags({ ...tags, [guuid]: tag });
      dirty.current = true;
    }
  };

  const addTag = () => {
    const tag = createEmptyTag();
    setTags({
      ...tags,
      [tag.guuid]: tag,
    });
  };

  const deleteTag = (guuid) => {
    const tagsCopy = { ...tags };
    delete tagsCopy[guuid];
    if (Object.keys(tagsCopy).length === 0) {
      const tag = createEmptyTag();
      tagsCopy[tag.guuid] = tag;
    }
    setTags(tagsCopy);
    dirty.current = true;
  };

  const tagDefined = (tag) => {
    return tag.stack && tag.attribute;
  };

  return (
    initialized && (
      <WidgetConfigureDialog
        {...props}
        dataProperty="tags"
        tagsSelected={tags}
        dataLabel={props.maxTags !== 1 ? "tags" : "tag"}
        prepareDataForWriteback={prepareTagsForWriteback}
        isDataValid={areTagsValid}
        titleBuilder={titleBuilder}
        dataDirty={dirty}
      >
        <WidgetTagsData
          {...props}
          tags={tags}
          addTag={addTag}
          deleteTag={deleteTag}
          setTagProps={setTagProps}
        />
      </WidgetConfigureDialog>
    )
  );
};

export default WidgetTagsConfigureDialog;
