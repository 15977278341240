import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createDashboard } from "store/operations";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";

const uuidv4 = require("uuid/v4");

const DEFAULT_DEVICE_LABEL = "stack";

const DEFAULT_INDICATOR_COLORS = {
  level0: green[500],
  level1: blue[500],
  level2: yellow[600],
  level3: red[500],
  baseline: green[500],
};

const DEFAULT_RANGE_MIN = 0;
const DEFAULT_RANGE_MAX = 1000;

export const DRAWER_WIDTH = 400;

export const APP_BAR_HEIGHT = 64;

const getConnectionStatus = (stack) => {
  if ("cs" in stack) {
    return parseInt(stack.cs);
  }
  return 1;
};

const useTimeout = (callback, delay) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      let id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
};

const isUserAdmin = (user) =>
  !!user.userRoles && user.userRoles.indexOf("admin") !== -1;

const updateLinearFunctionParams = (options, stackAttrs) => {
  const retval = {};
  //const options = this.props.widget?.options || {};
  /*const stackAttrs = ISXUtils.unpackTags(
    this.props.widget?.tags || [],
    this.props.stacks
  );*/
  if (options.linearfn?.type === "custom") {
    stackAttrs.forEach((entry) => {
      const stack = entry.stack;
      const attrs = entry.attributes;
      attrs.forEach((a) => {
        //console.log("stack and attr are", stack.guuid, a);
        retval[stack.guuid + "|" + a] = {};
        if (options.linearfn?.tagprop && a in stack["data_config"]) {
          retval[stack.guuid + "|" + a].slope =
            parseFloat(stack["data_config"][a].numeric?.linearfn?.slope) || 1.0;
          retval[stack.guuid + "|" + a].intercept =
            parseFloat(stack["data_config"][a].numeric?.linearfn?.intercept) ||
            0.0;
        }
        if (!options.linearfn?.tagprop) {
          retval[stack.guuid + "|" + a].slope =
            parseFloat(options.linearfn?.slope) || 1.0;
          retval[stack.guuid + "|" + a].intercept =
            parseFloat(options.linearfn?.intercept) || 0.0;
        }
      });
    });
  }
  return retval;
};

const linearFunction = (value, slope, intercept) => {
  if (
    typeof value === "number" &&
    typeof slope === "number" &&
    typeof intercept === "number"
  ) {
    return value * slope + intercept;
  }
  return value;
};

const formattedValue = (value, isxContext) => {
  const vtype = typeof value;
  if (value != null && vtype !== "number" && vtype !== "string") {
    value = JSON.stringify(value);
  } else if (vtype === "number") {
    if (
      isxContext &&
      isxContext.notations &&
      isxContext.notations.decimalDigits
    ) {
      value = value.toFixed(isxContext.notations.decimalDigits);
    } else if (Math.floor(value) === value) {
      value = Math.trunc(value);
    } else if (!Number.isInteger(value)) {
      value = value < 1 && value > -1 ? value.toPrecision(4) : value.toFixed(1);
    }
  }
  return value;
};

const d3ValueFormat = (value, isxContext) => {
  if (
    isxContext &&
    isxContext.notations &&
    isxContext.notations.decimalDigits
  ) {
    return `.${isxContext.notations.decimalDigits}f`;
  } else if (Number.isInteger(value) || Math.floor(value) === value) {
    return "d";
  } else {
    return value < 1 && value > -1 ? ".4r" : ".1f";
  }
};

const getDeviceLabel = (theme, uppercase = false, plural = false) => {
  const labels = theme.labels || {};
  const label = plural
    ? labels.devicePlural || labels.device
      ? `${labels.device}s`
      : `${DEFAULT_DEVICE_LABEL}s`
    : labels.device || DEFAULT_DEVICE_LABEL;
  return uppercase ? label.charAt(0).toUpperCase() + label.slice(1) : label;
};

// copy dashboard contents (use as template); replace all guuids with new ones;
// copied dashboard will be returned, but this function will not cause it to be
// created in the backend
function copyDashboardAsTemplate(template, titleAnnotation) {
  // only take certain attrs from template
  const { title, widgets = [], widgets_layout = {} } = template;

  // update title
  const newTitle = title ? `${title} (${titleAnnotation || "Copy"})` : "";
  const dashboard = {
    title: newTitle,
    widgets,
    widgets_layout,
  };

  // update guuids in widgets
  // expect to find them on each widget, widget tags, and widget tag indicators
  // need mapping to update layouts
  const template2CopyWidgets = {};
  dashboard.widgets.forEach((w) => {
    // give widget a new guuid
    w.guuid = template2CopyWidgets[w.guuid] = uuidv4();
    // remove any dashboard pointer, for good hygiene
    delete w.dashboard;
    // update any tag guuids
    (w.tags || []).forEach((t) => {
      // give tag a new guuid
      t.guuid = uuidv4();
      // update any tag indicators
      (t.indicators || []).forEach((i) => {
        // give indicator a new guuid
        i.guuid = uuidv4();
      });
    });
  });
  Object.values(widgets_layout).forEach((layout) => {
    layout.forEach((w) => {
      w.i = template2CopyWidgets[w.i];
    });
  });

  return dashboard;
}

const unpackTags = (tags, stacks) => {
  const stacks2attrs = {};
  tags.forEach((t) => {
    let attrs = stacks2attrs[t.stack];
    if (!attrs) {
      attrs = stacks2attrs[t.stack] = [];
    }
    const tnames = t.attribute.split("|"); //check for derived tags
    if (tnames.length > 1) {
      if (tnames[1].endsWith("lc") && !attrs.includes(tnames[0])) {
        // check if the tag is computed locally and is not already in attrs list
        attrs.push(tnames[0]);
      }
    } else if (!attrs.includes(tnames[0])) {
      attrs.push(tnames[0]);
    }
  });

  // filter out stacks not found
  const stackAttrs = Object.entries(stacks2attrs)
    .map((entry) => {
      const guuid = entry[0];
      const attrs = entry[1];
      const st = stacks[guuid];
      return { stack: st, attributes: attrs };
    })
    .filter((entry) => !!entry.stack);
  return stackAttrs;
};

const createTagKeyFromStackAndAttr = (guuid, attr) => `${guuid}|${attr}`;

const tagKeysToTags = (tagKeys) =>
  tagKeys.map((key) => {
    const [, guuid, attribute] = key.match(/(.*?)\|(.*)/);
    return { guuid, attribute };
  });

const getOrgsForTags = (tags, stacks2orgs) => {
  const orgids = new Set();
  tags.forEach((tag) => {
    const oids = stacks2orgs[tag.guuid];
    oids && oids.forEach((oid) => orgids.add(oid));
  });
  return orgids;
};

const LESS_THAN = -2;
const LESS_THAN_EQUALS = -1;
const GREATER_THAN = 1;
const GREATER_THAN_EQUALS = 2;
const EQUALS = 3;

const CONDITION_MAPPING = {
  "<": LESS_THAN,
  "<=": LESS_THAN_EQUALS,
  ">": GREATER_THAN,
  ">=": GREATER_THAN_EQUALS,
  "=": EQUALS,
};

const numberOrString = (val) => (isNaN(val) ? val : Number(val));

const sortedIndicators = (indicators) =>
  indicators
    .map((indicator) => {
      return {
        value: numberOrString(indicator.value),
        options: indicator.options || {},
        color: (indicator.options || {}).color,
        condition: CONDITION_MAPPING[indicator.condition] || 0,
      };
    })
    .sort((a, b) => {
      const aval = a.condition < 0 ? -a.value : a.value;
      const bval = b.condition < 0 ? -b.value : b.value;
      if (aval < bval || a.condition < b.condition) {
        return -1;
      } else if (aval > bval || a.condition > b.condition) {
        return 1;
      } else {
        return 0;
      }
    });

const indicatorToOp = (indicator) => {
  let op = null;
  switch (indicator.condition) {
    case EQUALS:
      op = (val) => val === indicator.value && indicator.options;
      break;
    case GREATER_THAN:
      op = (val) => val > indicator.value && indicator.options;
      break;
    case GREATER_THAN_EQUALS:
      op = (val) => val >= indicator.value && indicator.options;
      break;
    case LESS_THAN:
      op = (val) => val < indicator.value && indicator.options;
      break;
    case LESS_THAN_EQUALS:
      op = (val) => val <= indicator.value && indicator.options;
      break;
    default:
      op = (val) => null;
      break;
  }
  return op;
};

const buildIndicators = (indicators) =>
  sortedIndicators(indicators).map(indicatorToOp);

const getTagName = (tagId, config) => config?.[tagId]?.name ?? tagId;
const getTagUnits = (tagId, config) => config?.[tagId]?.unit;

const DERIVED_TAGS_DISPLAY = {
  "ma-10sm-lc": "(Avg)",
  "ma-10-lc": "(Avg)",
};

const getStackName = (stack, default_name = "") => {
  return (
    stack?.name ||
    JSON.parse(stack?.dev_session || "{}")["stack.label"] ||
    default_name
  );
};
const getTagDisplayText = (tagId, config, includeUnits = false) => {
  const attrParts = tagId.split("|");
  let displayText = getTagName(attrParts[0], config);
  if (includeUnits) {
    const units = getTagUnits(attrParts[0], config);
    if (units) {
      displayText += `(${units})`;
    }
  }
  if (attrParts.length > 1) {
    const derivedPartDisplay = DERIVED_TAGS_DISPLAY[attrParts[1]];
    displayText += derivedPartDisplay
      ? ` ${derivedPartDisplay}`
      : `|${attrParts[1]}`;
  }
  return displayText;
};

const getTagIndicators = (tagConfig) => {
  const indicators = [];
  let addzeromark = true;
  Object.entries(tagConfig?.threshold || {}).forEach((th) => {
    const [thlevel, thvalues] = th;
    if (
      thlevel.startsWith("level") &&
      !Number.isNaN(Number.parseInt(thvalues["high"]))
    ) {
      if ("high" in thvalues) {
        const indicator = {};
        indicator["condition"] = ">";
        indicator["value"] = Number.parseInt(thvalues["high"]);
        indicator["options"] = {
          color: DEFAULT_INDICATOR_COLORS[thlevel] || grey[500],
        };
        indicators.push(indicator);
      }
      if ("low" in thvalues) {
        const indicator = {};
        indicator["condition"] = "<";
        indicator["value"] = thvalues["low"];
        indicator["options"] = {
          color: DEFAULT_INDICATOR_COLORS[thlevel] || grey[500],
        };
        indicators.push(indicator);
      }
    } else if (
      thlevel === "baseline" &&
      !Number.isNaN(Number.parseInt(thvalues))
    ) {
      addzeromark = false;
      indicators.push({
        condition: ">",
        value: Number.parseInt(thvalues),
        options: { color: DEFAULT_INDICATOR_COLORS[thlevel] || grey[500] },
      });
      indicators.push({
        condition: "<=",
        value: Number.parseInt(thvalues),
        options: { color: DEFAULT_INDICATOR_COLORS[thlevel] || grey[500] },
      });
    }
  });
  if (indicators.length && addzeromark) {
    //add the level0 indicator (normal range) which is greater than the numeric.min (if specified in the tag or default of zero)
    indicators.push({
      condition: ">",
      value: Number.parseInt(tagConfig?.numeric?.min || "0"),
      options: { color: green[500] },
    });
  }
  return indicators;
};

const getTagRange = (tagConfig) => {
  return {
    min: parseInt(tagConfig?.numeric?.min || DEFAULT_RANGE_MIN),
    max: parseInt(tagConfig?.numeric?.max || DEFAULT_RANGE_MAX),
  };
};

//For UE Systems tag templates, filter out those not relevant to the tags
const filterAlertTemplates = (tagId, templates) => {
  for (const value of Object.values(templates)) {
    if (tagId.startsWith("sensor") && value.tagprefix.includes("sensor")) {
      return [value];
    } else if (tagId.startsWith("lube_") && value.tagprefix.includes("lube_")) {
      return [value];
    } else if (tagId.startsWith("vol_") && value.tagprefix.includes("vol_")) {
      return [value];
    } else if (
      tagId.startsWith("isxreserved|connected") &&
      value.tagprefix.includes("isxreserved|connected")
    ) {
      return [value];
    }
  }
  return Object.values(templates);
};

const escapeTagPeriods = (tagId) => tagId.replaceAll(".", "%2E");

const strcasecmp = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());

const defaultExports = {
  getConnectionStatus,
  isUserAdmin,
  formattedValue,
  updateLinearFunctionParams,
  linearFunction,
  d3ValueFormat,
  getDeviceLabel,
  copyDashboardAsTemplate,
  unpackTags,
  createTagKeyFromStackAndAttr,
  tagKeysToTags,
  getOrgsForTags,
  sortedIndicators,
  indicatorToOp,
  buildIndicators,
  getTagName,
  getTagDisplayText,
  getTagIndicators,
  getTagRange,
  filterAlertTemplates,
  useTimeout,
  getStackName,
  escapeTagPeriods,
  strcasecmp,
};
export default defaultExports;

export const ISXContext = React.createContext({});
export const ErrorContext = React.createContext({});

export const DashboardsContext = React.createContext(null);

export const AlertsAddressBookContext = React.createContext({
  sendTo: [],
  setSendTo: () => {},
});

export const ErrorProvider = ({ children }) => {
  const [errors, setErrors] = useState({});

  const setErrorById = useCallback((id, flag) => {
    setErrors((errors) => {
      if ((errors[id] ?? false) !== flag) {
        const newErrors = { ...errors, [id]: flag };
        return newErrors;
      } else {
        return errors;
      }
    });
  }, []);

  return (
    <ErrorContext.Provider value={{ errors, setErrors, setErrorById }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const DashboardsContextProvider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerExpanded, setDrawerExpanded] = useState(new Set());
  const [drawerSelected, setDrawerSelected] = useState(new Set());

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addDashboard = useCallback(
    (contents = null) => {
      const dashboard = contents || {
        widgets: [],
        widgets_layout: {},
      };
      return dispatch(createDashboard(dashboard)).then((rv) => {
        navigate("/dashboard/" + rv.guuid);
        // window.location.hash = "#/dashboard/" + rv.guuid;
      });
    },
    [dispatch, navigate]
  );

  return (
    <DashboardsContext.Provider
      value={{
        drawerOpen,
        setDrawerOpen,
        drawerExpanded,
        setDrawerExpanded,
        drawerSelected,
        setDrawerSelected,
        addDashboard,
      }}
    >
      {children}
    </DashboardsContext.Provider>
  );
};
