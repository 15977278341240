import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Widget from "../../widget/Widget";
import TableHead from "@material-ui/core/TableRow";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import CancelIcon from "@material-ui/icons/Cancel";

import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import MUIDataTable, { TableToolbar, TableFilterList } from "mui-datatables";
import {
  READ_WRITE_POLICY,
  DEFAULT_POLICIES,
} from "./OrganizationManagementWidgetConstants";
import CSVExporter, { CSV_TIMESTAMP_HEADERS } from "services/CSVExporter";
import QueryService from "services/QueryService";
import moment from "moment";
const EVENT_QUERY_TIME_INTERVAL_IN_DAYS = 30;
const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";
const UTC_DATE_FORMAT = "dddd DD MMMM, YYYY HH:mm:ss UTC";

const AttributeTableCell = withStyles((theme) => ({
  body: {
    verticalAlign: "bottom",
    paddingLeft: 12,
    paddingRight: 12,
    "&:last-child": {
      paddingRight: 12,
    },
  },
}))(TableCell);

const tableMuiTheme = (theme) =>
  createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiPaper: {
        root: {
          display: "flex",
          flexFlow: "column",
          height: "100%",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          "&:nth-child(even)": {
            backgroundColor: "rgb(250, 250, 250)",
          },
          cursor: "pointer",
        },
      },
      MuiTableCell: {
        root: {
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
      MuiIconButton: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: "1em",
        },
      },
      MuiCheckbox: {
        root: {
          padding: 0,
        },
      },
    },
  });

const MemoizedTable = React.memo(MUIDataTable);

const OrganizationManagementShowUserLoginsDialog = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      let results;
      try {
        results = await QueryService.eventsQuery(
          props.user,
          moment
            .utc()
            .subtract(EVENT_QUERY_TIME_INTERVAL_IN_DAYS, "day")
            .valueOf(),
          0
        );
      } catch (e) {
        console.warn("get events exception", e);
        results = [];
      }
      if (results.length) {
        const entries = results[0].data.map((entry) => {
          const [timestamp, event] = entry;
          const eventCategory = event?.evcat || "User";
          const eventType = event?.evtype || "Email"; // later support other types
          const eventSource = event?.user || "Event Source";
          //const evdata = JSON.parse(event?.data || {});
          return {
            eventType: eventType,
            eventCategory: eventCategory,
            eventSource: eventSource,
            //eventData: evdata,
            eventData: event?.data || {},
            timestamp,
            /*...event,*/
          };
        });
        setData(entries);
      } else {
        setData([]);
      }
    };
    props.user && fetch();
  }, [props.user]);

  const columns = useRef([
    {
      name: "timestamp",
      label: "Timestamp",
      options: {
        // sortDirection: "desc",
        filterList: [],
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? moment(value).format(DATE_FORMAT) : "---",
      },
    },
    {
      name: "eventCategory",
      label: "Category",
      options: {
        // sortDirection: "none",
        filterList: [],
        // hint: "click on a row to see evt details",
      },
    },
    {
      name: "eventType",
      label: "Type",
      options: {
        // sortDirection: "none",
        filterList: [],
        // hint: "click on a row to see evt details",
      },
    },
    {
      name: "eventSource",
      label: "Source",
      options: {
        // sortDirection: "none",
        filterList: [],
        // hint: "click on a row to see evt details",
      },
    },
    {
      name: "eventData",
      label: "Data",
      options: {
        // sortDirection: "none",
        filterList: [],
        /*customBodyRender: (value, tableMeta, updateValue) =>
          value?.tag ? value?.tag : null,*/
      },
    },
  ]);

  const closeMainDialog = () => {
    //setNewUsers([newUser()]);
    props.handleClose();
  };

  const saveCSV = () => {
    /*const rows = [].concat(
      [["username", "password", "rights", "remarks"]],
      results.map((a) => {
        return [
          a?.email,
          a?.password || "",
          a?.policies ? getPolicyName(a?.policies[0] || null) : "",
          a?.result || a?.error,
        ];
      })
    );

    const filename = "CreateUpdateUsersForOrg_" + orgName + ".csv";
    CSVExporter.export(filename, rows);*/
  };

  return (
    <Grid>
      <Dialog
        open={props.isOpen}
        onClose={closeMainDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Recent Logins</DialogTitle>
        <DialogContent>
          <MuiThemeProvider theme={(theme) => tableMuiTheme(theme)}>
            <MemoizedTable
              data={data}
              columns={columns.current}
              components={{}}
              /*options={options.current}
              detailsOpen={detailsOpen} // this is to force row to highlight
              forceRender={forceRender}
              components={{
                TableToolbar: readOnly ? ReadonlyToolbar : TableToolbar,
                TableFilterList: readOnly
                  ? ReadonlyTableFilterList
                  : TableFilterList,
              }}*/
            />
          </MuiThemeProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMainDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default OrganizationManagementShowUserLoginsDialog;
