import React, { useMemo, useState } from "react";
import { Doughnut } from 'react-chartjs-2';
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";


import { makeStyles, withStyles } from "@material-ui/core/styles";

import NumberFormat from "react-number-format";
import ISXUtils from "services/Utils";

import Widget from "../../widget/Widget";
import CSVExporter from "services/CSVExporter";

const ANIMATION_DURATION = 500;

const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    bottom: 10,
    right: 10,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
})(Backdrop);

const useStyles = makeStyles({
  stickyColumn: {
    position: "sticky",
    left: 0,
    background: "white",
  },
});

const TagLabel = ({ tag, stacks }) => {
  const stack = stacks?.[tag.stack];

  const dev_session = stack?.dev_session;
  const stackTitle = useMemo(() => {
    const title = (stack?.name || JSON.parse(dev_session || "{}")["stack.label"]) ?? "?";
    return title;
  }, [dev_session]);

  const tagDisplayText = ISXUtils.getTagDisplayText(
    tag.attribute,
    stacks?.[tag.stack]?.data_config,
    true
  );
  return `${stackTitle}:${tagDisplayText}`;
};

const AnimatedTableCell = (props) => {
  const { children, direction, container, ...rest } = props;
  return (
    <TableCell {...rest}>
      <Slide
        container={container}
        direction={direction}
        in={true}
        timeout={ANIMATION_DURATION}
      >
        {children}
      </Slide>
    </TableCell>
  );
};

const ShiftWidget = (props) => {
  const [direction, setDirection] = useState("right");
  const [animating, setAnimating] = useState(false);
  const [cycleDisplayValue,setCycleDisplayValue] = useState([]);
  const [percentDisplayValue,setPercentDisplayValue] = useState([]);
  const animationContainerRef = React.useRef(null);
  const { widget, stacks, periods, data, loading, offset, setOffset } = props;
  const { tags = [], options } = widget;
  const { "periods.tracking": tracking, periods_to_show: periodsToShow = 0 } =
    options ?? {};
  const classes = useStyles();

  const exportToCSV = () => {
    const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

    // columns:
    // - tag
    // - period name
    // - period start in ISO 8601 format
    // - period end in ISO 8601 format
    // - period start in excel-friendly format
    // - period end in excel-friendly format
    // - tracking type ("counts" or "states")
    // - state (if tracking type is "states")
    // - summary unit (% of time, total time, counts; if tracking type is "states"),
    // - value
    const headers = [
      "Tag",
      "Period Name",
      "Period Start ISO 8601",
      "Period End ISO 8601",
      "Period Start",
      "Period End",
      "Tracking Type",
      "State",
      "Summary Unit",
      "Value",
    ];

    const body = tags.flatMap((tag) => {
      const dev_session = stacks?.[tag.stack]?.dev_session ?? "{}";
      const stackTitle = (stacks?.[tag.stack]?.name || JSON.parse(dev_session)["stack.label"]) ?? "?";
      const tagDisplayText = ISXUtils.getTagDisplayText(
        tag.attribute,
        stacks?.[tag.stack]?.data_config,
        false
      );
      const tagLabel = `${stackTitle}:${tagDisplayText}`;

      //const trackingType = tracking[tag.guuid]?.trackingType;
      const trackingType = "states";
      return periods.flatMap((period) => {
        const periodLabel = period.template.period.label ?? "";
        const startISO = period.start.format();
        const endISO = period.end.format();
        const start = period.start.format(DATE_TIME_FORMAT);
        const end = period.end.format(DATE_TIME_FORMAT);
        const dataKey = JSON.stringify([
          tag.stack,
          tag.attribute,
          period.startValue,
          period.endValue,
        ]);
        const value = data[dataKey];
        if (trackingType === "counts") {
          return [
            [
              tagLabel,
              periodLabel,
              startISO,
              endISO,
              start,
              end,
              trackingType,
              null,
              null,
              value,
            ],
          ];
        } else {
          // states
          const entries = Object.entries(value ?? []);
          const summaryUnit = tracking[tag.guuid]?.summaryUnit ?? "percent";
          return entries.map(([label, val]) => {
            let formattedValue;
            if (summaryUnit === "percent") {
              formattedValue = isNaN(val) ? null : Number(val);
            } else if (summaryUnit === "time") {
              formattedValue = moment
                .utc((isNaN(val) ? 0 : val) * 1000)
                .format("HH:mm:ss");
            } else {
              formattedValue = isNaN(val) ? null : Number(val);
            }
            return [
              tagLabel,
              periodLabel,
              startISO,
              endISO,
              start,
              end,
              trackingType ?? "states",
              label,
              summaryUnit,
              formattedValue,
            ];
          });
        }
      });
    });

    const filename = `${(widget.title ?? "Report")
      .replace(/\s+/g, "")
      .replace(/,/g, "-")}.csv`;

    CSVExporter.export(filename, [headers, ...body]);
  };

  const showPrevious = () => {
    setOffset((offset) => offset + Math.max(periodsToShow - 1, 1));
    setDirection("left");
    setAnimating(true);
    setTimeout(() => setAnimating(false), ANIMATION_DURATION);
  };

  const showNext = () => {
    setOffset((offset) => Math.max(0, offset - Math.max(periodsToShow - 1, 1)));
    setDirection("right");
    setAnimating(true);
    setTimeout(() => setAnimating(false), ANIMATION_DURATION);
  };

  const reset = () => {
    setOffset(0);
    setDirection("right");
    setAnimating(true);
    setTimeout(() => setAnimating(false), ANIMATION_DURATION);
  };

  const getCounts = () => {
    return [[1,'rgb(255, 99, 132)'],[" / ",'rgb(255, 255, 255)'],[1,'rgb(255, 99, 132)']]
  }

  const getShiftLabel = () => {
  let retval = "";
  if (periods.length) {
  const period = periods[0];
  retval =
  period.start.year() !== period.end.year() ||
  period.start.month() !== period.end.month() ||
  period.start.day() !== period.end.day()
    ? `${period.start.format(
        "ddd MMM D HH:mm"
      )} - ${period.end.format("ddd MMM D HH:mm")}`
    : `${period.start.format(
        "ddd MMM D HH:mm"
      )} - ${period.end.format("HH:mm")}`;
    }
  return retval
  }

  const getChartdata = () => {
    let retVal = {};
    const dataKey = (tags.length && periods.length) ? JSON.stringify([
      tags[0].stack,
      tags[0].attribute,
      periods[0].startValue,
      periods[0].endValue,
    ]) : "";
    const value = data[dataKey] || {};
    if (value?.percent && value?.count) {
      let labelColors = Object.fromEntries((stacks?.[tags[0].stack]?.data_config?.[tags[0].attribute]?.states || []).map((x) => {return [x.label,x.color]}))
      labelColors['No Data'] = 'rgb(158, 158, 158)';
      retVal.labels = Object.keys(value.percent).filter(x => (tracking?.[tags[0]?.guuid]?.display?.intervals?.labels ?? Object.keys(value.percent)).includes(x));
      retVal.datasets = [{
        data: retVal.labels.map((x) => value.percent[x]),//Object.values(value.percent),
        backgroundColor: retVal.labels.map((x) => { return labelColors?.[x] || 'rgb(0, 0, 0)' }) ,
        hoverOffset: 4
      }]

      //display cycles
      let cycleValue = [];
      Object.keys(value.count).filter(x => (tracking?.[tags[0]?.guuid]?.display?.transitions?.labels ?? Object.keys(value.count)).includes(x)).forEach( (x) => {
        cycleValue.push([value?.count[x],labelColors?.[x] || 'rgb(0, 0, 0)'])
        cycleValue.push([" / ",'rgb(0, 0, 0)'])
      })
      cycleValue.pop()
      cycleValue.unshift(["Transitions ::",'rgb(0, 0, 0)'])
      if (JSON.stringify(cycleValue) !== JSON.stringify(cycleDisplayValue)) {
        setCycleDisplayValue(cycleValue);
      }

      //display %
      let percentValue = [];
      retVal.labels.forEach( (x) => {
        percentValue.push([value?.percent[x].toString()+" %",labelColors?.[x] || 'rgb(0, 0, 0)'])
        percentValue.push([" / ",'rgb(0, 0, 0)'])
      })
      percentValue.pop()
      if (JSON.stringify(percentValue) !== JSON.stringify(percentDisplayValue)) {
        setPercentDisplayValue(percentValue);
      }
      //getShiftLabel();
    }
    else {
      if (percentDisplayValue.length) {
      setPercentDisplayValue([]);
      }
      if(cycleDisplayValue.length) {
      setCycleDisplayValue([]);
      }
    }
    return retVal;
}

  return (
    <Widget
      {...props}
      configurable={true}
      showDerivedTags={false}
      maxTags={1}
      dataType="tags"
      widgetTitle="OEE Report"
      exportToCSV={exportToCSV}
    >
      <div style={{ height: "calc(100% - 36px)", overflow: "auto" }}>
        <Backdrop
          style={{ height: "calc(100% - 20px)", top: 20, zIndex: 100 }}
          open={loading && periods.length > 0}
        >
          <Paper style={{ backgroundColor: "white", padding: 10 }}>
            Loading Data...
          </Paper>
    </Backdrop>
    <LimitedBackdrop
     style={{ height: "calc(100% - 50px)", top: 20, zIndex: 100 }}
                open = { !(loading || percentDisplayValue.length || cycleDisplayValue.length)}
                //open={props.status ? false : showBackdrop}
                //onClick={(e) => viewPrevious()}
              >
                <Typography variant="button">Not Available</Typography>
              </LimitedBackdrop>
    <Typography
    style = {{
      display: "flex",
      width: "100%",
      height: "15%",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      fontSize: "15px",
      //backgroundColor: "#00aabb"
    }}
    > {getShiftLabel()}
    </Typography>
    {(tracking?.[tags[0]?.guuid]?.display?.intervals?.enabled ?? true) && (
    <>
    <Typography
    style = {{
      display: "flex",
      width: "100%",
      height: "10%",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      fontSize: "18px",
      //backgroundColor: "#00aabb"
    }}
    > {
    percentDisplayValue.map((x)=> {return <span style={{color: x[1] }}>{x[0]}</span>})
    }
    </Typography>
    <Doughnut data={getChartdata()} />
    </>
    )}
    {(tracking?.[tags[0]?.guuid]?.display?.transitions?.enabled ?? true) &&
    <Typography
    style = {{
      display: "flex",
      width: "100%",
      height: "10%",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      fontSize: "15px",
      //backgroundColor: "#00aabb"
    }}
    > {
    cycleDisplayValue.map((x)=> {return <span style={{color: x[1] }}>{x[0]}</span>})
    }
    </Typography>
}
      </div>
      <Grid container style={{ height: 36 }} justify="space-between">
        <Grid item>
          <Button onClick={showPrevious} disabled={loading}>
            <NavigateBeforeIcon />
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={reset} disabled={loading || offset === 0}>
            Reset
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={showNext} disabled={loading || offset === 0}>
            Next <NavigateNextIcon />
          </Button>
        </Grid>
      </Grid>
    </Widget>
  );
};

export default ShiftWidget;
