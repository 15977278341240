import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useSelector } from "react-redux";
import { RangeQueriesService } from "services/RangeQueryService";
import ISXUtils from "services/Utils";
import { TimeframeService } from "services/TimeframeService";
import WebWorker from "components/workers/workerSetup";
import countsProcessingWorker from "components/workers/countsProcessingWorker";
import stateTableProcessingWorker from "components/workers/stateTableProcessingWorker";
import { connectWidget } from "../../widget-connector/WidgetConnector";
import { PeriodsWidget as PeriodsWidgetComponent } from "components/widgets";
import TagsSubscriptionsContext from "services/TagsSubscriptionsService";
import TagEventIntervalsContext from "services/TagEventIntervalsService";

import moment from "moment-timezone";
import { default as _defaults } from "lodash/defaults";
import { default as _last } from "lodash/last";
import { default as _uniq } from "lodash/uniq";
import { default as _isEqual } from "lodash/isEqual";
import { default as _pick } from "lodash/pick";
import { default as _dropRight } from "lodash/dropRight";

const DAYS_OF_WEEK = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
const ISO_DAYS_OF_WEEK = ["MO", "TU", "WE", "TH", "FR", "SA","SU"];
const REFRESH_INTERVAL = 60000;
const CHECK_NEXT_INTERVAL = 10000;
const MAX_EVENTS_MULTIPLIER = {'day':1,'week':7,'month':31}

const rangeQueries = async (tags, startTime, endTime, window, orgids,smtype=null,segments=null,slots=null,tz=Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  //short circuit if window is greater than 24 hours
  if((!smtype) && window > TimeframeService.MAX_EVENTS_WINDOW*MAX_EVENTS_MULTIPLIER['day']) {
    console.log("incompatible for range",smtype,window);
    return tags.map((t) => {
      return {guuid:t.guuid,attr:t.attribute,data:{},summarized:true}
  })
  }
  return RangeQueriesService.query(
    tags,
    startTime,
    endTime,
    window,
    orgids,
    //smtype,
    true,
    segments,
    slots,
    tz
  ).catch((err) => {
    return {};
  });
};

const _getSubscriptionStartTime = (lastEvent, duration, now) => {
  if (lastEvent) {
    const lastTimestamp = lastEvent.x + 1;
    const startTime = moment.utc(lastTimestamp);
    if (now.diff(startTime, "seconds") < duration) {
      return lastTimestamp;
    }
  }
  return now
    .clone()
    .subtract(duration, "seconds")
    .add(REFRESH_INTERVAL, "milliseconds")
    .valueOf();
};

const tagAttributeToRoot = (attr) => {
  const attrParts = attr.split("|"); //check for derived tags
  const derived = attrParts.length > 1;
  return derived ? attrParts[0] : attr;
};

const getSlopeAndInterceptFromLinearFn = (linearfn) => {
  const asNumber = (raw) => {
    let val = parseFloat(raw);
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  };

  const { slope: slopeRaw, intercept: interceptRaw } = linearfn ?? {};
  const slope = asNumber(slopeRaw) || 1;
  const intercept = asNumber(interceptRaw);
  return { slope, intercept };
};

const PeriodsWidget = (props) => {
  const [periods, setPeriods] = useState([]);
  const [offset, setOffset] = useState(0);
  const [periodData, setPeriodData] = useState({});
  const [loading, setLoading] = useState(true);
  const [readyTags, setReadyTags] = useState([]);
  const rawStreams = useSelector((state) => state.isx.streams);
  const refreshIntervals = useSelector((state) => state.isx.refreshIntervals);
  const tagEventIntervals = useSelector((state) => state.isx.tagEventIntervals);
  const stacks2orgs = useSelector((state) => state.isx.stacks2orgs);
  const subscriptions = useRef({});
  const periodsTracked = useRef({});
  // if between periods cache start time of next period
  const lookaheadStart = useRef();
  const tagMetadata = useRef({});
  const tagStateProperties = useRef({});
  const trackingOptions = useRef({});
  const countsWorker = useRef();
  const statesWorker = useRef();
  const checkNextTimeout = useRef(null);
  // track when we want to show "loading" to user
  const loadPending = useRef(false);
  const pendingMessages = useRef(0);
  const lastTagEventIntervals = useRef({});
  const lastScheduleRunTime = useRef(0);

  const subscriptionsContext = useContext(TagsSubscriptionsContext);
  const eventIntervalsContext = useContext(TagEventIntervalsContext);

  const { widget, stacks } = props;
  const { tags = [], options = {} } = widget;
  const { "periods.tracking": latestTrackingOptions = {} } = options;
  const createStateValues = (summary, summaryUnit) => {
    const _createStateValuesHelper = (summary, callback) => {
      const labelIds = Array.from(summary.labels.keys()).sort(
        (a, b) => a - b
      );
      return labelIds.reduce((acc, id) => {
        acc[summary.labels.get(id)] = (acc[summary.labels.get(id)] ?? 0) + callback(id);
        return acc;
      }, {});
    };
    if (summaryUnit === "percent") {
      const totalDuration = Array.from(summary.durations.values()).reduce(
        (total, current) => total + current
      );
      return _createStateValuesHelper(summary, (id) =>
        Number((((summary.durations.get(id) ?? 0) / totalDuration) * 100).toFixed(2))
      );
    } else if (summaryUnit === "time") {
      return _createStateValuesHelper(summary, (id) =>
        Number(((summary.durations.get(id) ?? 0) / 1000.0).toFixed(2))
      );
    } else if (summaryUnit === "count") {
      return _createStateValuesHelper(
        summary,
        (id) => summary.cycles.get(id) ?? 0
      );
    }
  };
  const processSummaryResults = (key, summaryTotals,smtype) => {
    /*
    const labels = new Map(states.map((st) => [st.id, st.label]));*/
  
    //console.log("process summary resuults",key,summaryTotals,smtype);
    const trackedPeriod = periodsTracked.current[key];
    if (trackedPeriod) {
        //const summaryUnit = trackingOptions.current[trackedPeriod.tagid]?.summaryUnit ?? "percent";
        //const values = summaryUnit === "all" ? {'percent':createStateValues(summary, 'percent'),'time':createStateValues(summary, 'time')} : createStateValues(summary, summaryUnit)
        //console.log("summary and final values",summary,summaryUnit,values);
      if (smtype === "counts") {
        setPeriodData((data) => {
          return { ...data, [key]: summaryTotals?.counts ?? 0 };
        });
      }
      else if (smtype === "states") {
        const [stackid, attr] = JSON.parse(key);
        const tpkey = `${stackid}|${attr}`;
        const states = tagStateProperties.current[tpkey]?.states ?? []
        const durationinseconds = {};
        const percents = {};
        const totalDuration = (Array.from(Object.values(summaryTotals?.duration ?? {})).reduce(
          (total, current) => total + current, 0
        ))/1000.0;
        const labels = new Set(states.map((st) => st.label).concat(Object.keys(summaryTotals?.duration ?? [])))
        //console.log("tot duration and labels",totalDuration,labels);
        labels.forEach((label) => {
          //console.log("label is",label,summaryTotals.duration?.[label]);
          durationinseconds[label] = Number((summaryTotals.duration?.[label] ?? 0) /1000.0).toFixed(2)
          percents[label] = Number((((durationinseconds?.[label] ?? 0) / totalDuration) * 100).toFixed(2))
        })
        //const durationinseconds = (a) => { return Object.fromEntries(Object.entries(a).map(([key,value]) => [key,Number(value / 1000.0).toFixed(2)]))}
        //console.log("dur sec and %",durationinseconds,percents);
        const values = {'time':durationinseconds,'count':summaryTotals?.transitions,'percent':percents};
        setPeriodData((data) => {
          return { ...data, [key]: values };
        });
      }
    }
    if (--pendingMessages.current === 0) {
      setLoading(false);
    }
  }

  const postMessage = useCallback((key, entry) => {
    pendingMessages.current++;
    if (entry?.summaryTotals) {
      processSummaryResults(key,entry?.summaryTotals,trackingOptions.current[entry.tagid]?.trackingType ?? "states")
    }
    else {
      const trackingType =
        trackingOptions.current[entry.tagid]?.trackingType ?? "states";
      const metadataKey = `${entry.stackid}|${entry.attr}`;
      const { updateInterval, edgesOnly } =
        tagMetadata.current[metadataKey] ?? {};
      const end = Math.min(
        Math.max(
          entry.lastRequestTime - updateInterval,
          _last(entry.data?.rawData)?.x ?? 0
        ),
        entry.end
      );
      //console.log("do local compute",key,trackingType,metadataKey,end);
      if (trackingType === "counts") {
        countsWorker.current.postMessage({
          dataset: entry.data,
          startTime: entry.start,
          endTime: end,
          countType: "sum",
          match: [null, null],
          key,
        });
      } else if (trackingType === "states") {
        const tpkey = `${entry.stackid}|${entry.attr}`;
        const states = tagStateProperties.current[tpkey]?.states ?? [];
        statesWorker.current.postMessage({
          dataset: entry.data,
          startTime: entry.start,
          endTime: end,
          edgesOnly,
          states,
          key,
        });
      }
    }
  }, []);

  useEffect(() => {
    countsWorker.current = new WebWorker(countsProcessingWorker);
    countsWorker.current.addEventListener("message", (event) => {
      const { key, total } = event.data;
      const trackedPeriod = periodsTracked.current[key];
      if (trackedPeriod) {
        setPeriodData((data) => {
          return { ...data, [key]: total };
        });
      }
      if (--pendingMessages.current === 0) {
        setLoading(false);
      }
    });

    statesWorker.current = new WebWorker(stateTableProcessingWorker);
    statesWorker.current.addEventListener("message", (event) => {
      const { key, result } = event.data;
      //console.log("in stateworker",key,result);
      const [stackid, attr] = JSON.parse(key);
      const tpkey = `${stackid}|${attr}`;
      const states = tagStateProperties.current[tpkey]?.states ?? [];
      const labels = new Map(states.map((st) => [st.id, st.label]));
      const summary = result.history.reduce(
        (totals, state) => {
          const duration = state.end - state.start;
          totals.durations.set(
            state.id,
            duration +
              (totals.durations.has(state.id)
                ? totals.durations.get(state.id)
                : 0)
          );

          totals.cycles.set(
            state.id,
            1 + (totals.cycles.has(state.id) ? totals.cycles.get(state.id) : 0)
          );
          if (!totals.labels.has(state.id)) {
            totals.labels.set(state.id, state.label);
          }
          return totals;
        },
        { durations: new Map(), cycles: new Map(), labels }
      );

      const trackedPeriod = periodsTracked.current[key];
      if (trackedPeriod) {
        //const summaryUnit =trackingOptions.current[trackedPeriod.tagid]?.summaryUnit ?? "percent";
        //const values = createStateValues(summary, summaryUnit);
        const summaryUnit = "all";
        //const summaryUnit = trackingOptions.current[trackedPeriod.tagid]?.summaryUnit ?? "percent";
        const values = summaryUnit === "all" ? {'count':createStateValues(summary, 'count'),'percent':createStateValues(summary, 'percent'),'time':createStateValues(summary, 'time')} : createStateValues(summary, summaryUnit)
        //console.log("value after state worker",key,values);
        setPeriodData((data) => {
          return { ...data, [key]: values };
        });
      }
      if (--pendingMessages.current === 0) {
        setLoading(false);
      }
    });

    // clear timeouts on unmount, terminate workers
    return () => {
      Object.values(subscriptions.current).forEach((subId) =>
        subscriptionsContext.unsubscribe(subId)
      );
      subscriptions.current = {};
      clearTimeout(checkNextTimeout.current);
      countsWorker.current && countsWorker.current.terminate();
      statesWorker.current && statesWorker.current.terminate();
    };
  }, [subscriptionsContext]);

  const {
    periods: configuredPeriods,
    periods_to_show: periodsToShow = 0,
    timezone,
    timerange = "day"
  } = options;

  // map periods by day in descending order; these will
  // be the period templates for each day; working periods
  // will have actual start/end dates and times
  const periodTemplatesByDay = useMemo(() => {
    const pbd =
      configuredPeriods?.reduce((acc, p) => {
        const { byweekday: days = [] } = p;
        const mstart = moment.utc(p.start);
        const start = {
          hour: mstart.hour(),
          minute: mstart.minute(),
        };
        days.reduce((acc, d) => {
          _defaults(acc, { [d]: [] })[d].push({ period: p, day: d, start });
          return acc;
        }, acc);
        return acc;
      }, {}) ?? {};
    Object.values(pbd).forEach((ps) =>
      ps.sort(
        (a, b) =>
          a.period.start - b.period.start ||
          (a.period["duration.value"] ?? 0) - (b.period["duration.value"] ?? 0)
      )
    );
    let firstPeriod, lastPeriod, previousPeriod;
    DAYS_OF_WEEK.forEach((day) => {
      const periods = pbd[day] ?? [];
      periods.forEach((p) => {
        if (!firstPeriod) {
          firstPeriod = p;
        }
        if (previousPeriod) {
          previousPeriod.next = p;
          p.previous = previousPeriod;
        }
        lastPeriod = p;
        previousPeriod = p;
      });
    });
    if (firstPeriod && lastPeriod) {
      lastPeriod.next = firstPeriod;
      firstPeriod.previous = lastPeriod;
    }
    return pbd;
  }, [configuredPeriods]);

  //console.log("periods defined are",configuredPeriods,periodTemplatesByDay);
  const adjustStartFromTemplate = useCallback((start, template) => {
    start.set("hour", template.start.hour);
    start.set("minute", template.start.minute);
    start.set("second", 0);
  }, []);

  const getEndFromStartAndTemplate = useCallback(
    (start, template) =>
      start.clone().add(template.period["duration.value"] ?? 0, "hours"),
    []
  );

  const createPeriod = useCallback((start, end, template) => {
    const _roundToNearestSecond = (dt) =>
      Math.floor(dt.valueOf() / 1000) * 1000;
    return {
      start,
      end,
      startValue: _roundToNearestSecond(start),
      endValue: _roundToNearestSecond(end),
      template,
    };
  }, []);

  const getCurrentOrClosestPeriod = useCallback(
    (now, periodTemplatesByDay) => {
      const _getNextPeriodStart = (now, template) => {
        const nextPeriodTemplate = template.next;
        const nextPeriodDayIdx = DAYS_OF_WEEK.indexOf(nextPeriodTemplate.day);
        const nextPeriodStart = now.clone();
        nextPeriodStart.day(
          nextPeriodTemplate !== template && nextPeriodDayIdx >= startIdx
            ? nextPeriodDayIdx
            : nextPeriodDayIdx + 7
        );
        adjustStartFromTemplate(nextPeriodStart, template.next);
        return nextPeriodStart;
      };

      const startIdx = now.day();
      //if(configuredPeriods.length === 1 && (configuredPeriods[0]?.timerange === "month" || configuredPeriods[0]?.timerange === "week")) {
      if (timerange === "month" || timerange === "week") {
        const rangetemplate = configuredPeriods[0];
        const mstart = moment.utc(rangetemplate.start);
        const mperiod = { 
          period: rangetemplate, 
          day: rangetemplate?.dayofweek ?? "MO", 
          start: {
            hour: mstart.hour(),
            minute: mstart.minute(),
          },
          allslots:configuredPeriods
        }
        const start = now.clone();
        if (timerange==="month") {
          start.set('date',1); //1st of the month
        }
        else if (timerange==="week") {
          const diffdays = startIdx - DAYS_OF_WEEK.indexOf(rangetemplate?.startofweek || "MO")
          //console.log(startIdx,rangetemplate?.startofweek,DAYS_OF_WEEK.indexOf(rangetemplate?.startofweek || "MO"),diffdays)
          start.subtract((diffdays < 0 ? diffdays + 7 : diffdays),'day')
        }
        adjustStartFromTemplate(start,mperiod);
        //const end = moment({'year':start.year(),'month':start.month(),'date':start.date()}).add(1,timerange)
        const end = start.clone().add(1,timerange);
        return {
        period: createPeriod(start.clone(), end, mperiod),
        lookaheadStart: start.clone().add(1,timerange),
        }
      }
      else {
      let dayIdx = startIdx;
      let currentOrClosestPeriod;
      let nextPeriodStart;
      for (let i = 0; i < 7; i++) {
        const day = DAYS_OF_WEEK[dayIdx];
        const dayPeriodTemplates = (periodTemplatesByDay[day] ?? [])
          .slice()
          .reverse();
        const start = now.clone();
        start.day(startIdx - i);
        for (let template of dayPeriodTemplates) {
          adjustStartFromTemplate(start, template);
          const end = getEndFromStartAndTemplate(start, template);
          if (now >= start && now <= end) {
            // we are currently in active period
            currentOrClosestPeriod = createPeriod(start.clone(), end, template);
            nextPeriodStart = _getNextPeriodStart(now, template);
            break;
          } else if (!currentOrClosestPeriod && now > end) {
            currentOrClosestPeriod = createPeriod(start.clone(), end, template);
            nextPeriodStart = _getNextPeriodStart(now, template);
            // no break - keep looking for a current period
          }
        }
        if (currentOrClosestPeriod) {
          break;
        }
        dayIdx = (dayIdx + 6) % 7;
      }
      return {
        period: currentOrClosestPeriod,
        lookaheadStart: nextPeriodStart,
      };
    }
    },
    [timerange, adjustStartFromTemplate, configuredPeriods, createPeriod, getEndFromStartAndTemplate]
  );

  useEffect(() => {
    const _checkForNextPeriod = () => {
      // check to see if a new period has started
      const now = timezone ? moment().tz(timezone) : moment();
      if (lookaheadStart.current && now >= lookaheadStart.current) {
        try {
          const { period: latestPeriod, lookaheadStart: pendingStart } =
            getCurrentOrClosestPeriod(now, periodTemplatesByDay);
          setPeriods((periods) => [latestPeriod, ..._dropRight(periods)]);
          lookaheadStart.current = pendingStart;
        } catch (err) {
          console.error(err);
        }
      }
    };

    const _checkForNextPeriodRepeat = () => {
      try {
        _checkForNextPeriod();
      } finally {
        checkNextTimeout.current = setTimeout(
          _checkForNextPeriodRepeat,
          CHECK_NEXT_INTERVAL
        );
      }
    };

    clearTimeout(checkNextTimeout.current);
    // ony poll for next period if not offset (set to past)
    if (offset === 0) {
      checkNextTimeout.current = setTimeout(
        _checkForNextPeriodRepeat,
        CHECK_NEXT_INTERVAL
      );
    }
  }, [getCurrentOrClosestPeriod, periodTemplatesByDay, timezone, offset]);

  useEffect(() => {
    // should recompute all periods if period templates have been modified, or
    // the # of periods to show has been changed (could in the future optimize
    // the latter)
    try {
      const now = timezone ? moment().tz(timezone) : moment();
      const { period: latestPeriod, lookaheadStart: pendingStart } =
        getCurrentOrClosestPeriod(now, periodTemplatesByDay);
      // at this point, should either have current period or preceding period,
      // unless there are no periods at all; need to follow prior periods
      // step back into prior periods until we have all we need
      const activePeriods = [];
      if (latestPeriod) {
        let curPeriod = latestPeriod;
        const curStart = curPeriod.start.clone();
        
        //activePeriods.push(curPeriod);
        //console.log("latest and current",offset,latestPeriod,pendingStart);
        //if(configuredPeriods.length === 1 && (configuredPeriods[0]?.timerange === "month" || configuredPeriods[0]?.timerange === "week")) {
        if (timerange === "month" || timerange === "week") {  
          const rangetemplate = configuredPeriods[0];
          const mstart = moment.utc(rangetemplate.start);
          const mperiod = { 
            period: rangetemplate, 
            day: rangetemplate?.dayofweek ?? "MO", 
            start: {
              hour: mstart.hour(),
              minute: mstart.minute(),
            }
          }
          if(offset) {
            //console.log("offset is",offset)
            curPeriod.start.subtract(offset,timerange)
            curPeriod.end.subtract(offset,timerange)
            curPeriod.startValue = Math.floor(curPeriod.start.valueOf() / 1000) * 1000;
            curPeriod.endValue = Math.floor(curPeriod.end.valueOf() / 1000) * 1000;
            //console.log("after offset",curPeriod,activePeriods)
          }
          activePeriods.push(curPeriod);
          //console.log("after offset",curPeriod,activePeriods)
          for (let i = 1; i < periodsToShow; i++) {
            //console.log("additional period additions")
            curPeriod = createPeriod(
              curPeriod.start.clone().subtract(1,timerange),
              curPeriod.end.clone().subtract(1,timerange),
              mperiod
            );
            activePeriods.push(curPeriod);
          }
        }
        else {
          //console.log("daily case offset is",offset)
          for (let i = 0; i < offset; i++) {
            const priorPeriodTemplate = curPeriod.template.previous;
            const priorDay = priorPeriodTemplate.day;
            const curDay = curPeriod.template.day;
            if (
              priorDay !== curDay ||
              priorPeriodTemplate === curPeriod.template
            ) {
              let dayIdx = DAYS_OF_WEEK.indexOf(priorDay);
              if (dayIdx >= DAYS_OF_WEEK.indexOf(curDay)) {
                dayIdx -= 7;
              }
              curStart.day(dayIdx);
            }
            if (priorPeriodTemplate !== curPeriod.template) {
              adjustStartFromTemplate(curStart, priorPeriodTemplate);
            }
            const curEnd = getEndFromStartAndTemplate(
              curStart,
              priorPeriodTemplate
            );
            curPeriod = createPeriod(
              curStart.clone(),
              curEnd,
              priorPeriodTemplate
            );
          }
          activePeriods.push(curPeriod);
          //console.log("daily case after offset",curPeriod,activePeriods)
          for (let i = 1; i < periodsToShow; i++) {
            const priorPeriodTemplate = curPeriod.template.previous;
            const priorDay = priorPeriodTemplate.day;
            const curDay = curPeriod.template.day;
            if (
              priorDay !== curDay ||
              priorPeriodTemplate === curPeriod.template
            ) {
              let dayIdx = DAYS_OF_WEEK.indexOf(priorDay);
              if (dayIdx >= DAYS_OF_WEEK.indexOf(curDay)) {
                dayIdx -= 7;
              }
              curStart.day(dayIdx);
            }
            if (priorPeriodTemplate !== curPeriod.template) {
              adjustStartFromTemplate(curStart, priorPeriodTemplate);
            }
            const curEnd = getEndFromStartAndTemplate(
              curStart,
              priorPeriodTemplate
            );
            curPeriod = createPeriod(
              curStart.clone(),
              curEnd,
              priorPeriodTemplate
            );
            activePeriods.push(curPeriod);
          }
        }
      }
      lookaheadStart.current = offset > 0 ? undefined : pendingStart;
      //console.log("activeperiods",activePeriods);
      setPeriods(activePeriods);
    } catch (err) {
      console.error(err);
    }
  }, [getCurrentOrClosestPeriod, createPeriod, adjustStartFromTemplate, getEndFromStartAndTemplate, periodTemplatesByDay, periodsToShow, timezone, offset, configuredPeriods, timerange]);

  const requestForData = useCallback(async () => {
    // separate tracked periods into buckets ny status (we don't really need
    // "done" here)
    try {
      const [pending] = Object.entries(periodsTracked.current).reduce(
        (acc, entry) => {
          const [, tracked] = entry;
          if (tracked.active) {
            const bucket = ["pending", "progressing", "done"].indexOf(
              tracked.status
            );
            if (bucket !== -1) {
              acc[bucket].push(tracked);
            }
          }
          return acc;
        },
        [[], [], []]
      );

      const getslots = () => {
        return configuredPeriods.map((cp) => {
          const weekbitmap = (cp?.byweekday ?? []).map((d) => Math.pow(2,ISO_DAYS_OF_WEEK.indexOf(d))).reduce((a, b) => a + b, 0);
          //console.log("cp and weekbitmap",cp?.byweekday,weekbitmap);
          return `${weekbitmap}_${(cp?.start ?? 0)}_${((cp?.start ?? 0)+((cp?.['duration.value'] ?? 24) * 3600000))}`
        })
      }
      //console.log("in requestfordata",getslots(),pending,stacks);
      const gotstackdata = pending.every((p) => {
        return stacks?.[p.stackid]?.data_config?.[p.attr] ? true : false
      });
      //console.log("gotstackdata",gotstackdata,props);
      if (!gotstackdata) {
        console.log("returning.. hopefully should get picked up later?");
        return
      }
      const promises = [];
      // note start and end are tracked since these may be different from what
      // is put in request
      const periodTimes = [];

      const now = moment.utc();
      const nowValue = now.valueOf();

      // handle pending; for each of these, fetch the entire range of the period
      let tagMatchingAttrs;
      const pendingTagEntries = pending.reduce((acc, entry) => {
        const { stackid, attr, start, end, awaiting } = entry;
        if (!awaiting) {
          const tagKey = ISXUtils.createTagKeyFromStackAndAttr(stackid, attr);
          const tagEntries = _defaults(acc, { [tagKey]: [] })[tagKey];
          tagEntries.push({ start, end });
          entry.awaiting = true;
          entry.lastRequestTime = nowValue;
        } else {
          console.log(nowValue, "already awaiting (1)", entry, "skip request");
        }
        return acc;
      }, {});
      const tagRanges = Object.entries(pendingTagEntries).map(
        ([tagKey, ranges]) => {
          const tagEventInterval = lastTagEventIntervals.current[tagKey] ?? 1;
          return { tagKey, tagEventInterval, ranges: ranges.sort() };
        }
      );
      if (tagRanges.length > 0) {
        const firstRanges = tagRanges[0];
        const allTagsAlign = tagRanges
          .slice(1)
          .every((tr) =>
            tr.ranges.every(
              (range, idx) =>
                range.start === firstRanges.ranges[idx].start &&
                range.end === firstRanges.ranges[idx].end
            )
          );
        if (allTagsAlign) {
          const tagKeys = tagRanges.map((tr) => tr.tagKey);
          const attrs = tagKeys.map((key) => {
            const [, stackid, attribute] = key.match(/(.*?)\|(.*)/);
            return { stackid, attribute };
          });
          const rootTags = _uniq(
            attrs.map(({ stackid, attribute }) => {
              const rootAttr = tagAttributeToRoot(attribute);
              return ISXUtils.createTagKeyFromStackAndAttr(stackid, rootAttr);
            })
          );
          tagMatchingAttrs = attrs.reduce((acc, a, idx) => {
            const rkey = rootTags[idx];
            _defaults(acc, { [rkey]: [] })[rkey].push(a);
            return acc;
          }, {});

          firstRanges.ranges.forEach((range) => {
            const maxWindow =
              firstRanges.tagEventInterval * TimeframeService.MAX_EVENTS_WINDOW*MAX_EVENTS_MULTIPLIER[timerange ?? 'day'];

            const window = Math.min(
              (range.end - range.start) / 1000,
              maxWindow
            );
            const groups = [];
            let lastGroup = null;
            rootTags.forEach((tagKey) => {
              const eventInterval = lastTagEventIntervals.current[tagKey];
              const numEvents = window / eventInterval;
              if (
                lastGroup &&
                lastGroup.expectedEvents + numEvents <=
                  TimeframeService.MAX_EVENTS_WINDOW
              ) {
                lastGroup.tagKeys.push(tagKey);
                lastGroup.expectedEvents += numEvents;
              } else {
                lastGroup = {
                  tagKeys: [tagKey],
                  expectedEvents: numEvents,
                };
                groups.push(lastGroup);
              }
            });
            groups.forEach(({ tagKeys }) => {
              const tags = ISXUtils.tagKeysToTags(tagKeys);
              const orgids = ISXUtils.getOrgsForTags(tags, stacks2orgs);
              const compatible = tags.every((t) => {
                return stacks?.[t.guuid]?.data_config?.[t.attribute]?.da ? true : false
              });
              //const sm = stacks?.[t.stack]
              promises.push(
                rangeQueries(tags, range.start, range.end, window, orgids,compatible,null,getslots(),timezone) //"states"
              );
              periodTimes.push([range.start, range.end]);
            });
          });
        } else {
          tagMatchingAttrs = {};
          tagRanges.forEach((tr) => {
            const [, stackid, attribute] = tr.tagKey.match(/(.*?)\|(.*)/);
            const rootAttr = tagAttributeToRoot(attribute);
            const rkey = ISXUtils.createTagKeyFromStackAndAttr(
              stackid,
              rootAttr
            );
            _defaults(tagMatchingAttrs, { [rkey]: [] })[rkey].push({
              stackid,
              attribute,
            });
            const tagEventInterval = lastTagEventIntervals.current[tr.tagKey];
            const maxWindow =
              tagEventInterval * TimeframeService.MAX_EVENTS_WINDOW*MAX_EVENTS_MULTIPLIER[timerange ?? 'day'];
            const oids = stacks2orgs[stackid];
            const tags = ISXUtils.tagKeysToTags([rkey]);
            tr.ranges.forEach((range) => {
              const window = Math.min(
                (range.end - range.start) / 1000,
                maxWindow
              );
              console.log("not align request",tags,window,getslots(),timezone);
              promises.push(
                rangeQueries(tags, range.start, range.end, window, oids,true,getslots(),timezone)
              );
              periodTimes.push([range.start, range.end]);
            });
          });
        }
      }

      if (loadPending.current) {
        promises.length > 0 && setLoading(true);
        loadPending.current = false;
      }

      const allResults = await Promise.all(promises);

      let haveData = false;
      allResults.forEach((results, idx) => {
        const [periodStart, periodEnd] = periodTimes[idx];
        results.forEach((result) => {
          const { guuid, attr, data } = result;
          const tagKey = ISXUtils.createTagKeyFromStackAndAttr(guuid, attr);
          const matches = tagMatchingAttrs[tagKey];
          if (!data) {
            matches.forEach(({ stackid, attribute }) => {
              const key = JSON.stringify([
                stackid,
                attribute,
                periodStart,
                periodEnd,
              ]);
              const entry = periodsTracked.current[key];
              if (entry) {
                entry.awaiting = false;
              }
            });
          } else {
            (matches ?? []).forEach(({ stackid: guuid, attribute: attr }) => {
              const key = JSON.stringify([guuid, attr, periodStart, periodEnd]);
              const entry = periodsTracked.current[key];
              // TODO: need to handle client-side computed attrs
              if (entry) {
                const tagKey = ISXUtils.createTagKeyFromStackAndAttr(
                  guuid,
                  attr
                );
                const tmeta = tagMetadata.current[tagKey] ?? {};
                const {
                  updateInterval,
                  eventInterval = 1,
                  eventIntervalEstimated,
                  missingDataCutoff,
                  numeric,
                  edgesOnly,
                } = tmeta;
                if (result?.summarized) {
                  if (trackingOptions.current[entry.tagid]?.trackingType === "counts") {
                    entry.summaryTotals  = {"counts": data?.totals ?? 0}
                  }
                  else {
                  entry.summaryTotals  = data?.totals ?? []
                  }
                }
                entry.status =
                 (entry?.summaryTotals || entry.end - _last(data)?.[0] < eventInterval * 1000)
                    ? "done"
                    : "progressing";
                const lastEvent = _last(entry.data?.rawData);
                //console.log("lastevent and entry",lastEvent,entry,result?.summarized,trackingOptions.current[entry.tagid]?.trackingType)
                const rawData = entry?.summaryTotals ? [] : (data ?? [])
                  .filter((d) => d[0] >= entry.start && d[0] <= entry.end)
                  .map((d) => {
                    return { x: d[0], y: d[1] };
                  });
                if (entry.status === "progressing") {
                  const duration = (periodEnd - periodStart) / 1000;
                  let subscriptionStartTime = _getSubscriptionStartTime(
                    _last(rawData),
                    duration,
                    now
                  );
                  const subId = subscriptionsContext.subscribe(
                    tagKey,
                    subscriptionStartTime,
                    REFRESH_INTERVAL / 1000,
                    {
                      maxWindowInSeconds: duration,
                      eventInterval: lastTagEventIntervals.current[tagKey],
                    }
                  );
                  subscriptions.current[tagKey] = subId;
                }
                if (numeric?.linearfn) {
                  const { slope, intercept } = getSlopeAndInterceptFromLinearFn(
                    numeric?.linearfn
                  );
                  rawData.forEach((event) => {
                    event.y = event.y * slope + intercept;
                  });
                }
                entry.data = lastEvent
                  ? {
                      rawData: [...entry.data?.rawData, ...rawData],
                    }
                  : {
                      rawData: rawData ?? [],
                    };
                // do we need to handle derived tags?
                if (entry.data?.rawData?.length > 0 || entry?.summaryTotals) {
                  Object.assign(entry.data, {
                    updateInterval,
                    eventInterval: eventIntervalEstimated
                      ? null
                      : eventInterval * 1000,
                    missingDataCutoff,
                    edgesOnly,
                  });
                  postMessage(key, entry);
                  haveData = true;
                } else {
                  setPeriodData((data) => {
                    return { ...data, [key]: undefined };
                  });
                }
                entry.awaiting = false;
              }
            });
          }
        });
      });
      if (!haveData) {
        // worker not invoked, so mark initialized here
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, [stacks, props, configuredPeriods, timerange, stacks2orgs, timezone, subscriptionsContext, postMessage]);

  useEffect(() => {
    const [, progressing] = Object.entries(periodsTracked.current).reduce(
      (acc, entry) => {
        const [, tracked] = entry;
        if (tracked.active) {
          const bucket = ["pending", "progressing", "done"].indexOf(
            tracked.status
          );
          if (bucket !== -1) {
            acc[bucket].push(tracked);
          }
        }
        return acc;
      },
      [[], [], []]
    );

    const now = moment.utc();
    const refreshIntervalInSeconds = REFRESH_INTERVAL / 1000;

    const scheduleRunTime = subscriptionsContext.getScheduleRuntime(
      refreshIntervalInSeconds
    );
    if (!scheduleRunTime || scheduleRunTime <= lastScheduleRunTime.current) {
      return;
    }
    lastScheduleRunTime.current = scheduleRunTime;

    progressing.forEach((entry) => {
      const { stackid, attr, start, end } = entry;
      const periodKey = JSON.stringify([stackid, attr, start, end]);
      const tagKey = ISXUtils.createTagKeyFromStackAndAttr(stackid, attr);
      const rootAttr = tagAttributeToRoot(attr);
      const rkey = ISXUtils.createTagKeyFromStackAndAttr(stackid, rootAttr);
      const timeseries = rawStreams[rkey];
      const subId = subscriptions.current[rkey];
      if (!subId) {
        return;
      }

      const lastEvent = _last(timeseries);
      if (lastEvent?.x >= end) {
        subscriptionsContext.unsubscribe(subId);
        delete subscriptions.current[rkey];
        entry.status = "done";
      } else {
        const earliestStartTime = _getSubscriptionStartTime(
          lastEvent,
          end - start,
          now
        );
        subscriptionsContext.updateSubscription(subId, earliestStartTime);
      }
      const lastPriorEvent = _last(entry.data?.rawData);
      const rawData = (timeseries ?? []).filter(
        (d) =>
          d.x >= (lastPriorEvent ? lastPriorEvent.x + 1 : entry.start) &&
          d.x <= entry.end
      );
      const tmeta = tagMetadata.current[tagKey] ?? {};
      const {
        updateInterval,
        eventInterval = 1,
        eventIntervalEstimated,
        missingDataCutoff,
        numeric,
        edgesOnly,
      } = tmeta;

      if (numeric?.linearfn) {
        const { slope, intercept } = getSlopeAndInterceptFromLinearFn(
          numeric?.linearfn
        );
        rawData.forEach((event) => {
          event.y = event.y * slope + intercept;
        });
      }
      entry.data = lastPriorEvent
        ? {
            rawData: [...entry.data?.rawData, ...rawData],
          }
        : {
            rawData: rawData ?? [],
          };
      if (entry.data?.rawData?.length > 0) {
        Object.assign(entry.data, {
          updateInterval,
          eventInterval: eventIntervalEstimated ? null : eventInterval * 1000,
          missingDataCutoff,
          edgesOnly,
        });
        postMessage(periodKey, entry);
      } else {
        setPeriodData((data) => {
          return { ...data, [periodKey]: undefined };
        });
      }
    });
  }, [postMessage, rawStreams, subscriptionsContext]);

  // handle refresh/update intervals separately; by itself, an interval update
  // should not trigger a worker message
  useEffect(() => {
    // ensure have mappings from stacks to orgs before continuing
    if (Object.keys(stacks2orgs).length === 0) {
      return;
    }
    // ensure we have all tag event intervals, and that they are current
    const tagKeys = tags.map((t) => `${t.stack}|${t.attribute}`);
    const relevantTagEventIntervals = _pick(tagEventIntervals, tagKeys);
    const missingIntervals = tagKeys.filter((key) => !tagEventIntervals[key]);
    missingIntervals.forEach(
      (tagKey) => (relevantTagEventIntervals[tagKey] = undefined)
    );
    const eventIntervalsChanged = !_isEqual(
      relevantTagEventIntervals,
      lastTagEventIntervals.current
    );
    if (eventIntervalsChanged) {
      lastTagEventIntervals.current = relevantTagEventIntervals;
      Object.entries(relevantTagEventIntervals).forEach(
        ([key, eventInterval]) => {
          if (eventInterval) {
            // proactively set event interval for existing tags
            const curMetadata = tagMetadata.current[key];
            if (curMetadata) {
              Object.assign(curMetadata, {
                eventInterval,
              });
            }
          }
        }
      );
      // what is missing - need to register interest to cause fetch
      if (missingIntervals.length > 0) {
        eventIntervalsContext.registerInterest(missingIntervals);
        // waut until we have all intervals to continue
        return;
      } else {
        setReadyTags(tags);
      }
    }
    tags.forEach((t) => {
      const st = stacks?.[t.stack];
      if (st) {
        const updateInterval = (refreshIntervals[st.guuid] ?? 0) * 1000;
        const attr = t.attribute;
        const key = `${t.stack}|${attr}`;
        const curMetadata = tagMetadata.current[key];
        const lastUpdateInterval = curMetadata?.updateInterval;
        if (updateInterval !== lastUpdateInterval) {
          if (!curMetadata) {
            tagMetadata.current[key] = {
              updateInterval,
            };
          } else {
            Object.assign(curMetadata, {
              updateInterval,
            });
          }
          Object.entries(periodsTracked.current).forEach(([, entry]) => {
            if (entry.stackid === t.stack && entry.attr === attr) {
              if (entry.active && entry.data?.rawData?.length > 0) {
                Object.assign(entry.data, {
                  updateInterval,
                });
              }
            }
          });
        }
      }
    });
  }, [
    eventIntervalsContext,
    refreshIntervals,
    stacks,
    tagEventIntervals,
    tags,
    stacks2orgs,
  ]);

  // need to check and update tag state properties and metadata
  useEffect(() => {
    readyTags.forEach((t) => {
      const st = stacks?.[t.stack];
      if (st) {
        const key = `${t.stack}|${t.attribute}`;
        const dataConfig = st.data_config ?? {};
        const attr = t.attribute;

        const curMetadata = tagMetadata.current[key];
        const curStateProps = tagStateProperties.current[key] ?? {};

        const rootAttr = tagAttributeToRoot(attr);
        const tagProperties = dataConfig[rootAttr];

        const newStateProps = _pick(tagProperties ?? {}, [
          "states",
          "statesNoDataColor",
          "statesNoDataMinDuration",
          "statesSeriesType",
          "numeric",
          "s_int",
        ]);

        let update = false;
        let linearfnAdj;
        // compare to what is already cached
        if (!_isEqual(curStateProps, newStateProps)) {
          // not equal so need to update state computations for tag
          update = true;
          // check linearfn update separately, as need to refetch all if it
          // changes
          const lastLinearfn = curStateProps?.numeric?.linearfn;
          if (!_isEqual(lastLinearfn, newStateProps?.numeric?.linearfn)) {
            // need to "adjust" data to reflect new linearfn by also
            // reverting old linearfn
            const { slope: lastSlope, intercept: lastIntercept } =
              getSlopeAndInterceptFromLinearFn(lastLinearfn);
            const { slope: newSlope, intercept: newIntercept } =
              getSlopeAndInterceptFromLinearFn(
                newStateProps?.numeric?.linearfn
              );
            const slopeAdj = newSlope / lastSlope;
            const interceptAdj = newIntercept - lastIntercept;
            linearfnAdj = { slopeAdj, interceptAdj };
          }
          tagStateProperties.current[key] = newStateProps;
        }

        const eventInterval = lastTagEventIntervals.current[key];
        // need to know if interval explicit or computed, as won't pass computed to worker
        const eventIntervalEstimated = !newStateProps.s_int;

        const missingDataCutoff =
          newStateProps.statesNoDataMinDuration?.type === "set"
            ? (newStateProps.statesNoDataMinDuration?.value ?? 0) * 1000
            : null;

        const edgesOnly = newStateProps.statesSeriesType === "edges";

        if (!curMetadata) {
          tagMetadata.current[key] = {
            eventInterval,
            eventIntervalEstimated,
            missingDataCutoff,
            edgesOnly,
            numeric: newStateProps.numeric,
          };
          update = true;
        } else {
          Object.assign(curMetadata, {
            eventInterval,
            eventIntervalEstimated,
            missingDataCutoff,
            edgesOnly,
            numeric: newStateProps.numeric,
          });
        }
        if (update) {
          let needData = false;
          Object.entries(periodsTracked.current).forEach(([key, entry]) => {
            if (entry.stackid === t.stack && entry.attr === attr) {
              if (entry.status === "done" && !entry.data) {
                entry.status = "pending";
                needData = entry.active;
              } else if (entry.active && entry.data?.rawData?.length > 0) {
                if (linearfnAdj) {
                  const { slopeAdj, interceptAdj } = linearfnAdj;
                  entry.data.rawData.forEach((event) => {
                    event.y = event.y * slopeAdj + interceptAdj;
                  });
                }
                Object.assign(entry.data, {
                  eventInterval: eventIntervalEstimated
                    ? null
                    : eventInterval * 1000,
                  missingDataCutoff,
                  edgesOnly,
                });
                postMessage(key, entry);
              }
            }
          });
          if (needData) {
            console.log("needdata 1")
            loadPending.current = true;
            requestForData();
          }
        }
      }
    });
  }, [stacks, requestForData, tagEventIntervals, readyTags, postMessage]);

  useEffect(() => {
    const updates = Object.entries(latestTrackingOptions)
      .flatMap(([tagid, toptions]) => {
        if (!_isEqual(trackingOptions.current[tagid], toptions)) {
          // need to iterate over tracked periods to find which are relevant
          return Object.entries(periodsTracked.current)
            .map(([key, entry]) => entry.tagid === tagid && [key, entry])
            .filter(Boolean);
        } else {
          return null;
        }
      })
      .filter(Boolean);
    trackingOptions.current = latestTrackingOptions;
    let needData = false;
    updates.forEach(([key, entry]) => {
      if (entry.status === "done" && !entry.data) {
        entry.status = "pending";
        needData = entry.active;
      } else if (entry.active && entry.data?.rawData?.length > 0) {
        postMessage(key, entry);
      }
    });
    if (needData) {
      console.log("needdata 2");
      loadPending.current = true;
      requestForData();
    }
  }, [latestTrackingOptions, postMessage, requestForData]);

  useEffect(() => {
    const activePeriodsTracked = periods.reduce((acc, period) => {
      readyTags.forEach((tag) => {
        const attr = tag.attribute;
        // want start and end times as integers
        const start = period.startValue;
        const end = period.endValue;
        const key = JSON.stringify([tag.stack, attr, start, end]);

        acc[key] = periodsTracked.current[key] ?? {
          stackid: tag.stack,
          tagid: tag.guuid,
          attr,
          start,
          end,
          status: "pending",
        };
        acc[key].active = true;
        // want only inactive periods left in periods tracked
        delete periodsTracked.current[key];
      });
      return acc;
    }, {});

    // only have inactive periods now, so clear data and mark inactive
    Object.entries(periodsTracked.current).forEach(([key, tracked]) => {
      if (tracked.status === "progressing") {
        // remove subscriptions for any that were in progress
        const tagKey = ISXUtils.createTagKeyFromStackAndAttr(
          tracked.stackid,
          tracked.attr
        );
        const subId = subscriptions.current[tagKey];
        if (subId) {
          subscriptionsContext.unsubscribe(subId);
          delete subscriptions.current[tagKey];
        } else {
          console.warn(
            "cannot find subscription to remove for period formerly in progress",
            key
          );
        }
        tracked.status = "pending";
      }
      delete tracked.data;
      tracked.active = false;
    });

    // remove inactive periods
    periodsTracked.current = {
      ...periodsTracked.current,
      ...activePeriodsTracked,
    };

    loadPending.current = true;
    requestForData();
  }, [periods, requestForData, readyTags, subscriptionsContext]);

  return (
    <PeriodsWidgetComponent
      {...props}
      periods={periods}
      data={periodData}
      loading={loading}
      offset={offset}
      setOffset={setOffset}
    />
  );
};

export default connectWidget(PeriodsWidget);
