import React, { useEffect, useState, useRef } from "react";
import { ExportWidget } from "components/widgets";
import { connectWidget } from "../../widget-connector/WidgetConnector";
import QueryService from "services/QueryService";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { isxOperations } from "store";

const ExportContainerWidget = (props) => {
  const [data, setData] = useState(null);

  function fetchData() {
    QueryService.getExports().then(e => { console.log("DATA", e); setData(e && e.length ? e : null) });
  }

  function newRequest(body) {
    QueryService.putExport(body).then(fetchData)
  }
  
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 180000); // 180,000ms = 3 minutes (refresh interval)
    return () => clearInterval(intervalId);
  }, []);
  
  return (
    <ExportWidget {...props} data={data} newRequest={newRequest}/>
  );
};

//hack a connection to intercept the options
// so that they never save -- HACK HACK HACK
// This is b/c we are using the options menu really as
// a selector for options to use for an API call
// to add the request

export const connectNoSaveWidget = (WrappedNoSaveWidget) => {
  const NSWidgetWrapper = (props) => {
    const username = useSelector((state) => state.isx.username);
    const password = useSelector((state) => state.isx.password);
    const stacks = useSelector((state) => state.isx.stacks);
    const user = useSelector((state) => state.isx.user);
    const org = useSelector((state) => state.isx.org);
    return (
      <WrappedNoSaveWidget
      {...props}
      username={username}
      password={password}
      stacks={stacks}
      user={user}
      org={org}
      />
    );
  };
  NSWidgetWrapper.propTypes = {
    widget: PropTypes.object.isRequired,
  };
  return NSWidgetWrapper;
};


export default connectNoSaveWidget(ExportContainerWidget);
