import React from "react";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { connectWidget } from "../../widget-connector/WidgetConnector";
import { EventsWidget as EventsWidgetComponent } from "components/widgets";
import QueryService from "services/QueryService";
import _ from "lodash";

const EventsWidget = (props) => {
  const [interval, setInterval] = useState(null);
  const [data, setData] = useState([]);

  const timeout = useRef(null);
  const start = useRef(null);
  const end = useRef(null);
  const windowStart = useRef(null);
  const tick = useRef(0);
  const lastTimeframeType = useRef(null);
  const lastInterval = useRef(null);
  const lastLengthValue = useRef(null);
  const lastLengthUnits = useRef(null);
  const lastTimeframeStart = useRef(null);
  const lastWidgetStacks = useRef(null);
  const stackInfo = useRef(null);
  const widgetOptions = useRef({});
  const dataref = useRef([]);

  const { widget } = props;
  widgetOptions.current = widget.options || {};
  const { stacks: wstacks } = widget;
  const { timeframe = {} } = widgetOptions.current;
  const timeframeType = timeframe.type;
  const intervalValue = timeframe["interval.value"];
  const intervalUnits = timeframe["interval.units"];
  const lengthUnits = timeframe["length.units"];
  const lengthValue = timeframe["length.value"];
  const timeframeStart = timeframe.start;

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    const computeIntervalInSeconds = (val, units) => {
      let factor = 0;
      if (units === "seconds") {
        factor = 1;
      } else if (units === "minutes") {
        factor = 60;
      } else if (units === "hours") {
        factor = 3600;
      } else if (units === "days") {
        factor = 14400;
      }
      return val * factor;
    };
    const newInterval =
      Math.max(computeIntervalInSeconds(intervalValue, intervalUnits), 1) *
      1000;
    setInterval(newInterval);
  }, [intervalValue, intervalUnits]);

  useEffect(() => {
    if (!stackInfo.current && Object.keys(props.stacks).length > 0) {
      stackInfo.current = Object.values(props.stacks).reduce((acc, stack) => {
        const devSession = JSON.parse(stack["dev_session"]);
        const label = stack?.name || devSession["stack.label"] || "";
        const location = devSession["stack.location"] || "";
        acc[stack.guuid] = { label, location };
        return acc;
      }, {});
    }
  }, [props.stacks]);

  useEffect(() => {
    const fetch = async (mytick) => {
      if (timeframeType === "moving") {
        windowStart.current = moment
          .utc()
          .subtract(lengthValue, lengthUnits)
          .valueOf();
        if (!start.current) {
          start.current = windowStart.current;
          end.current = 0;
        }
      } else {
        // fixed timeframe
        if (!start.current) {
          start.current = moment.utc(timeframeStart);
          end.current = moment
            .utc(start.current)
            .add(lengthValue, lengthUnits)
            .valueOf();
          start.current = start.current.valueOf();
          windowStart.current = start.current;
        }
      }
      let results;
      try {
        results = await QueryService.eventsQuery(
          wstacks,
          start.current,
          end.current
        );
      } catch (e) {
        console.warn("get events exception", e);
        results = [];
      }

      if (mytick === tick.current) {
        const lastEntries = results
          .map((tag) => _.last(tag.data))
          .filter(Boolean);
        const lastEntriesTimestamps = lastEntries.map((entry) => entry[0]);
        start.current = Math.min(lastEntriesTimestamps);

        // filter existing data now out of range
        Object.keys(dataref.current).forEach((tguuid) => {
          const [, guuid] = tguuid.match(/(.*?)_isxreserved\|events/);
          if (wstacks.indexOf(guuid) === -1) {
            delete dataref.current[tguuid];
          } else {
            dataref.current[tguuid] = (dataref.current[tguuid] || []).filter(
              (entry) => entry[0] >= windowStart.current
            );
          }
        });
        // add any new results in, watching out for overlap of data
        results.forEach((tag) => {
          // const dataTag = (dataref.current[tag.guuid] || []).filter(
          //   entry => entry[0] >= windowStart.current
          // );
          const dataTag = dataref.current[tag.guuid] || [];
          const lastEntry = _.last(dataTag);
          if (lastEntry) {
            const lastTimestamp = lastEntry[0];
            tag.data = tag.data.filter((entry) => entry[0] > lastTimestamp);
          }
          dataref.current[tag.guuid] = [...dataTag, ...tag.data];
        });

        const events = Object.entries(dataref.current)
          .map(([tguuid, data]) => {
            const [, guuid] = tguuid.match(/(.*?)_isxreserved\|events/);
            const entries = data.map((entry) => {
              const [timestamp, event] = entry;
              const eventCategory = event?.evcat || "User";
              const eventType = event?.evtype || "Email"; // later support other types
              const eventSource = event?.user || "Event Source";
              const evdata =
                typeof event?.data == "string"
                  ? JSON.parse(event?.data || {})
                  : event?.data || {};
              const curStackInfo = stackInfo.current
                ? stackInfo.current[guuid] || {}
                : {};
              return {
                guuid,
                eventType: eventType,
                eventCategory: eventCategory,
                eventSource: eventSource,
                eventData: evdata,
                label: curStackInfo.label || "",
                location: curStackInfo.location || "",
                timestamp,
                /*...event,*/
              };
            });
            return entries;
          })
          .flat();
        setData(events);

        const isFinalized =
          timeframeType === "fixed" && results.every((tag) => tag.is_finalized);
        if (timeframeType === "moving" || !isFinalized) {
          timeout.current = setTimeout(() => {
            fetch(++tick.current);
          }, interval || 10000);
        }
      } else {
        console.warn("DON'T NEED THESE RESULTS, EXITING");
      }
    };

    let refetch = false;
    if (wstacks !== lastWidgetStacks.current) {
      lastWidgetStacks.current = wstacks;
      refetch = true;
    }
    if (timeframeType !== lastTimeframeType.current) {
      lastTimeframeType.current = timeframeType;
      start.current = null;
      dataref.current = [];
      refetch = true;
    }
    if (
      lengthValue !== lastLengthValue.current ||
      lengthUnits !== lastLengthUnits.current
    ) {
      lastLengthValue.current = lengthValue;
      lastLengthUnits.current = lengthUnits;
      start.current = null;
      dataref.current = {};
      refetch = true;
    }
    if (timeframeStart !== lastTimeframeStart.current) {
      lastTimeframeStart.current = timeframeStart;
      start.current = null;
      dataref.current = [];
      refetch = refetch || timeframeType === "fixed";
    }
    if (interval !== lastInterval.current) {
      lastInterval.current = interval;
      refetch = refetch || interval;
    }
    if (refetch && interval && wstacks && wstacks.length > 0) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      fetch(++tick.current);
    }
  }, [
    wstacks,
    timeframe,
    interval,
    timeframeType,
    lengthValue,
    lengthUnits,
    timeframeStart,
    props.stacks,
  ]);

  const updateWidgetOptions = (options) => {
    widgetOptions.current = { ...widgetOptions.current, ...options };
    props.updateWidget({
      options: { ...widgetOptions.current },
    });
  };

  return (
    <EventsWidgetComponent
      {...props}
      data={data}
      updateWidgetOptions={updateWidgetOptions}
    />
  );
};

export default connectWidget(EventsWidget);
