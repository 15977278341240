import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimezoneSelect from 'react-timezone-select'
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: "column",
    margin: theme.spacing(3),
    width: "calc(100% - 48px)",
  },
  timezoneContainer: {
    marginTop: theme.spacing(1),
    zIndex: 10,
  },
}));

//options.start: timestamp of start of data range
//options.end: timestamp of end of data range
//options.include-errtok: flag to include errtok values (defaults to false)
//options.include-header: flag to include header in csv
//options.one-file: flag to combine all tags to a single file
//options.tag-columns: arrange in columns per tag
//options.time-sensitivity: collapse times in tag-columns (mS)

const ExportOption = (props) => {
  const { options, setOptions } = props;
  const [error, setError] = useState(false);

  const classes = useStyles();
  
  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <FormLabel component="legend">Export Options</FormLabel>

      <FormControlLabel
        name="defaults"
        label="Single File Output"
        control={
          <Checkbox
            onChange={(event) => {
              const value = event.target.checked;
              props.setOptions({
                "one-file": value,
              });
            }}
            name="useSingle"
          />
        }
      />
      <FormControlLabel
        name="defaults"
        label="Tags in Columns"
        control={
          <Checkbox
            onChange={(event) => {
              const value = event.target.checked;
              props.setOptions({
                "tag-columns": value,
              });
            }}
            name="useSingle"
          />
        }
      />
      { props.options["tag-columns"] &&
        <FormControlLabel
          name="defaults"
          label="Time sensitivity: small value in integer mS (use carefully otherwise results will be unexpected)"
          control={
            <TextField
              id="ts"
              label="Time Sensitivity"
              onChange={(event) => {
                const val = event.target.value;
                props.setOptions({"time-sensitivity":
                                  val
                                 });}}
            />
                                            
          }
        />
      }
      <FormControlLabel
        name="defaults"
        label="Include Header in CSV"
        control={
          <Checkbox
            onChange={(event) => {
              const value = event.target.checked;
              props.setOptions({
                "include-header": value,
              });
            }}
            name="useSingle"
          />
        }
      />
      <FormControlLabel
        name="defaults"
        label="Include Error & Sync Values"
        control={
          <Checkbox
            onChange={(event) => {
              const value = event.target.checked;
              props.setOptions({
                "include-errtok": value,
              });
            }}
            name="useSingle"
          />
        }
      />
      <FormControl >
        <TextField
          id="start-time"
          label="Start Date Time eg. 2025-03-24T15:30:00Z"
          onChange={(event) => {
            const val = event.target.value;
            props.setOptions({"start":
                              val
                             });}}
            />
        </FormControl>
      <FormControl >
        <TextField
          id="end-time"
          label="End Date Time"
          onChange={(event) => {
            const val = event.target.value;
            props.setOptions({"end":
                              val
                             });}}
            />
        </FormControl>
      <FormControl >
        <TextField
          id="note"
          label="Note/Description"
          onChange={(event) => {
            const val = event.target.value;
            props.setOptions({"note":
                              val
                             });}}
            />
        </FormControl>

      <FormControl >
        <TextField
          id="email"
          label="Email when finished (optional)"
          onChange={(event) => {
            const val = event.target.value;
            props.setOptions({"email":
                              val
                             });}}
            />
        </FormControl>

      
    </FormControl>
  );
};

export default ExportOption;
