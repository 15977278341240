import React, { useRef, useContext } from "react";
import { TimeSeriesService } from "services/TimeSeriesService";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Column from "react-virtualized/dist/commonjs/Table/Column";
import Table from "react-virtualized/dist/commonjs/Table";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import ISXUtils, { ISXContext } from "services/Utils";
import { default as _last } from "lodash/last";
import moment from "moment";
import CSVExporter, { CSV_TIMESTAMP_HEADERS } from "services/CSVExporter";

import Widget from "../../widget/Widget";

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

const MA_DERIVED_TAG = "|ma-10sm-lc";

const TableWidget = (props) => {
  const dataArray = useRef([]);

  const isxContext = useContext(ISXContext);

  const createObjectFromDataArray = (data) => {
    const derivedTags = props.widget.tags.filter((t) =>
      t.attribute.endsWith(MA_DERIVED_TAG)
    );
    const derivedData = derivedTags.reduce((acc, dtag) => {
      const atag = `${dtag.stack}|${dtag.attribute.slice(
        0,
        -MA_DERIVED_TAG.length
      )}`;
      if (data[atag]) {
        acc[`${dtag.stack}|${dtag.attribute}`] = TimeSeriesService.ema(
          data[atag],
          10,
          { dataType: "xy" }
        );
      }
      return acc;
    }, {});

    if (Object.keys(derivedData).length > 0) {
      data = { ...data, ...derivedData };
    }
    let entries = {}; // map timestamps to values
    for (let tag in data) {
      // const attribute = tag.split("|")[1];
      let d = data[tag].slice().reverse();
      d.forEach((attributeInfo) => {
        let value = ISXUtils.formattedValue(attributeInfo.y, isxContext);
        const timestamp = attributeInfo.x;
        let entry = entries[timestamp];
        if (entry == null) {
          entry = entries[timestamp] = {};
        }
        entry[tag] = value;
      });
    }
    let array = Object.entries(entries).map((e) => {
      return { timestamp: e[0], ...e[1] };
    });
    array.sort((e1, e2) => e2.timestamp - e1.timestamp);
    array.forEach((e) => {
      e.timestampUnix = parseInt(e.timestamp);
      e.timestamp = moment(e.timestampUnix).format(DATE_FORMAT);
    });
    return array;
  };

  const createTableColumns = () => {
    const stacks = props.stacks;
    if (props.data.length !== 0) {
      let headerArray = [
        {
          Header: "Timestamp",
          accessor: "timestamp",
        },
      ];
      const includeStackName =
        new Set(
          (props.widget.tags || []).map((t) => {
            return t?.stack;
          })
        ).size > 1
          ? true
          : false;
      (props.widget.tags || []).forEach((t) => {
        let header = ISXUtils.getTagDisplayText(
          t.attribute,
          stacks?.[t.stack]?.data_config,
          true
        );
        if (includeStackName) {
          header = ISXUtils.getStackName(stacks[t.stack]) + "." + header;
        }
        const accessor = t.stack + "|" + t.attribute;
        headerArray.push({
          id: accessor,
          Header: header,
          accessor: accessor,
        });
      });
      return headerArray;
    }
  };

  const exportToCSV = () => {
    const data = dataArray.current;
    if (data.length > 0) {
      const stacks = props.stacks;
      const includeStackName =
        new Set(
          (props.widget.tags || []).map((t) => {
            return t?.stack;
          })
        ).size > 1
          ? true
          : false;
      const headers = (props.widget.tags || []).map((t) => {
        const st = stacks[t.stack] || {};
        const dconfig = st.data_config || {};
        let header = ISXUtils.getTagDisplayText(t.attribute, dconfig, true);
        if (includeStackName) {
          header = ISXUtils.getStackName(st) + "." + header;
        }
        return {
          accessor: t.stack + "|" + t.attribute,
          header: header,
        };
      });

      const rows = [
        CSV_TIMESTAMP_HEADERS.concat(headers.map((h) => h.header)),
      ].concat(
        data.map((d) =>
          CSVExporter.timestampColumns(d.timestampUnix).concat(
            headers.map((h) => {
              return d[h.accessor];
            })
          )
        )
      );

      const filename = CSVExporter.formatFilename(
        props.widgetTitle || "Table",
        _last(data).timestampUnix,
        data[0].timestampUnix
      );

      CSVExporter.export(filename, rows);
    }
  };

  const _rowStyle = ({ index }) => {
    let style = { borderBottom: "1px solid #e0e0e0" };
    if (index < 0) {
      style.textTransform = "none";
    } else if (index % 2 === 0) {
      // won't see border without this
      style.zIndex = 1;
    } else {
      style.backgroundColor = "#fafafa";
    }
    return style;
  };

  const columns = createTableColumns() || [];
  dataArray.current = createObjectFromDataArray(props.data);

  return (
    <Widget
      {...props}
      dataType="tags"
      widgetTitle="Table"
      exportToCSV={exportToCSV}
    >
      <div
        style={{ width: "100%", height: "100%" }}
        onClick={(ev) => {
          // stop propagation so this stack remains selected
          ev.stopPropagation();
        }}
      >
        <Card style={{ height: "100%" }}>
          <CardContent style={{ height: "calc(100% - 31px)" }}>
            <div style={{ marginTop: 0, height: "100%" }}>
              <AutoSizer>
                {({ width, height }) => (
                  <Table
                    width={width}
                    height={height}
                    headerHeight={30}
                    rowHeight={30}
                    rowCount={dataArray.current.length}
                    rowGetter={({ index }) => dataArray.current[index] || []}
                    rowStyle={_rowStyle}
                  >
                    <Column
                      key="timestamp"
                      label="Timestamp"
                      dataKey="timestamp"
                      width={200}
                      flexGrow={0}
                    />
                    {columns.slice(1).map((c) => (
                      <Column
                        key={c.accessor}
                        label={c.Header}
                        dataKey={c.accessor}
                        width={60}
                        flexGrow={1}
                        cellRenderer={(columnData) => {
                          const data = (columnData || {}).cellData;
                          if (data != null) {
                            const color = props.resolveIndicators(
                              c.accessor,
                              data
                            );
                            if (color) {
                              return (
                                <span style={{ color: color }}>{data}</span>
                              );
                            }
                          }
                          return data;
                        }}
                      />
                    ))}
                  </Table>
                )}
              </AutoSizer>
            </div>
          </CardContent>
        </Card>
      </div>
    </Widget>
  );
};

export default TableWidget;
