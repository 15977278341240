import React, { useState, useContext, useRef, useEffect, useMemo } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { blue } from "@material-ui/core/colors";

import StackManagementDialog from "components/widgets/stacks-management-widget/StackManagementDialog";
import WidgetConfigureDialog from "..//WidgetConfigureDialog";
import ComboBox from "components/ComboBox";
import ISXUtils, { ISXContext } from "services/Utils";

const USE_STACK_CONFIG = true;

const useStyles = makeStyles({
  openIcon: {
    color: blue[500],
    cursor: "pointer",
  },
});

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    borderWidth: 2,
    borderStyle: "solid",
    border: theme.palette.divider,
  },
}))(Tooltip);

const StackDetails = (props) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const { selectedStack } = props;

  return (
    <>
      <InfoTooltip
        title="Click to view/modify"
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <OpenInNewIcon fontSize="small" className={classes.openIcon} />
      </InfoTooltip>
      {open && (
        <StackManagementDialog
          detailsOpen={open}
          closeDetails={() => setOpen(false)}
          selectedStack={selectedStack}
          readOnly={false}
        />
      )}
    </>
  );
};

const StackTableCell = withStyles((theme) => ({
  head: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  body: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}))(TableCell);

const StackRow = (props) => {
  const isxContext = useContext(ISXContext);

  const setStack = (_, stack) => {
    props.selectStack(stack.guuid);
  };

  const { stack, stacks, stacksSortedByName } = props;

  return (
    <TableRow>
      <StackTableCell>
        {stacksSortedByName.length > 0 ? (
          <ComboBox
            value={stacks[stack]}
            options={stacksSortedByName}
            onChange={setStack}
            getOptionLabel={ISXUtils.getStackName}
          />
        ) : (
          <Typography>
            No {isxContext.labels.deviceCapitalized} Available
          </Typography>
        )}
      </StackTableCell>
      <TableCell style={{ paddingLeft: 0, paddingRight: 0 }}>
        {USE_STACK_CONFIG && stack && <StackDetails selectedStack={stack} />}
      </TableCell>
      <StackTableCell>
        {props.deleteStack && (
          <IconButton onClick={() => props.deleteStack(stack)}>
            <CancelIcon />
          </IconButton>
        )}
        {props.addStack && (
          <IconButton onClick={props.addStack} disabled={!stack}>
            <AddCircleIcon />
          </IconButton>
        )}
      </StackTableCell>
    </TableRow>
  );
};

const WidgetStacksData = (props) => {
  const isxContext = useContext(ISXContext);
  
  const { maxStacks } = props;

  const stacksSelected = [...props.stacksSelected];

  const stacksSortedByName = useMemo(
    () =>
      props.stacks
        ? Object.values(props.stacks).sort((a, b) =>
            ISXUtils.strcasecmp(
              ISXUtils.getStackName(a),
              ISXUtils.getStackName(b)
            )
          )
        : [],
    [props.stacks]
  );

  return (
    <Table>
            <TableHead>
        <TableRow>
          <StackTableCell>{isxContext.labels.deviceCapitalized}</StackTableCell>
          <StackTableCell />
          <StackTableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {stacksSelected.map((stack, idx) => {
          const addStack =
            idx === stacksSelected.length - 1 &&
            (!maxStacks || stacksSelected.length < maxStacks)
              ? props.addStack
              : null;
          const deleteStack = maxStacks !== 1 ? props.deleteStack : null;
          return (
            <StackRow
              {...props}
              key={stack}
              stack={stack}
              stacksSortedByName={stacksSortedByName}
              deleteStack={deleteStack}
              addStack={addStack}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

const WidgetStacksConfigureDialog = (props) => {
  const [stacksSelected, setStacksSelected] = useState();
  const [initialized, setInitialized] = useState(false);
  const dirty = useRef(false);
  const isxContext = useContext(ISXContext);

  const { widget, stacks = {}, maxStacks = Number.MAX_SAFE_INTEGER } = props;

  useEffect(() => {
    if (!initialized) {
      const wstackIds = widget.stacks || [];
      const initialStacksSelected = new Set();
      if (wstackIds.length > 0) {
        wstackIds.forEach((st) => {
          initialStacksSelected.add(st);
        });
      } else {
        initialStacksSelected.add(null);
      }
      setStacksSelected(initialStacksSelected);
      setInitialized(true);
    }
  }, [initialized, maxStacks, stacks, widget.stacks]);

  const selectStack = (guuid) => {
    if (props.maxStacks !== 1) {
      let newStacksSelected = new Set(stacksSelected);
      newStacksSelected.delete(null);
      newStacksSelected.add(guuid);
      setStacksSelected(newStacksSelected);
    } else {
      setStacksSelected(new Set([guuid]));
    }
    dirty.current = true;
  };

  const prepareStacksForWriteback = () => {
    const stackIds = [...stacksSelected];
    return { stacks: stackIds };
  };

  const areStacksValid = () => {
    return !stacksSelected.has(null);
  };

  const titleBuilder = (data) => {
    const stackIds = data.stacks;
    let title;
    if (stackIds.length === 1) {
      const stackid = stackIds[0];
      const stack = props.stacks[stackid] || {};
      const stackTitle =
        stack?.name || JSON.parse(stack.dev_session || "{}")["stack.label"];
      title = stackTitle;
    } else {
      title = props.widgetTitle || "Widget";
    }
    return title;
  };

  const addStack = () => {
    setStacksSelected(new Set([...stacksSelected, null]));
  };

  const deleteStack = (guuid) => {
    const stacksSelectedCopy = new Set(stacksSelected);
    stacksSelectedCopy.delete(guuid);
    if (stacksSelectedCopy.size === 0) {
      stacksSelectedCopy.add(null);
    }
    setStacksSelected(stacksSelectedCopy);
    dirty.current = true;
  };

  return (
    initialized && (
      <WidgetConfigureDialog
        {...props}
        dataProperty="stacks"
        stacksSelected={stacksSelected}
        dataLabel={
          props.maxStacks !== 1
            ? isxContext.labels.devicePlural
            : isxContext.labels.device
        }
        prepareDataForWriteback={prepareStacksForWriteback}
        isDataValid={areStacksValid}
        titleBuilder={titleBuilder}
        dataDirty={dirty.current}
      >
        <WidgetStacksData
          {...props}
          stacksSelected={stacksSelected}
          selectStack={selectStack}
          addStack={addStack}
          deleteStack={deleteStack}
        />
      </WidgetConfigureDialog>
    )
  );
};

export default WidgetStacksConfigureDialog;
