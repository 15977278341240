import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Dashboard as _DashboardComponent } from "../../components";
import { TagsSubscriptionsProvider } from "services/TagsSubscriptionsService";
import { TagEventIntervalsProvider } from "services/TagEventIntervalsService";
import Button from "@material-ui/core/Button";
import {
  getDashboard,
  getDashboards,
  // createDashboard,
  updateDashboard,
  removeDashboard,
  removeWidget,
  updateWidgetsLayout,
  getAllItems,
  updateUser,
  getOrgs,
} from "store/operations";
import { ISXContext, DashboardsContext } from "services/Utils";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { default as _isEqual } from "lodash/isEqual";

const uuidv4 = require("uuid/v4");
const ORG_ADMIN = "~01f0572050ec4f11e98f3295659e03fb04";
const DashboardComponent = React.memo(_DashboardComponent);

// if user has no stacks, the fetch interval to use
// const DEFAULT_FETCH_INTERVAL = 30000;
// const MIN_FETCH_INTERVAL = 10.0; // in seconds. The absolute minimum refresh rate for items

const ITEMS_FETCH_INTERVAL = 60000;

const DASHBOARD_REFETCH_INTERVAL = 30000; // milliseconds

const ORG_REFETCH_INTERVAL = 300000; //milliseconds (5 minutes)

const DASHBOARD_METADATA_FIELDS = [
  "guuid",
  "title",
  "g_owner",
  "acu_groups",
  "write_access_state",
  "autogenerated_stack",
  "autogenerated_dashboard",
  "isdefault",
];

const ITEM_METADATA_FIELDS = [
  "guuid",
  "dev_session",
  "cs",
  "cstime",
  "g_owner",
  "lastbpcktime",
  "lastbpuptime",
  "upd_int",
  "st_errors",
  "st_time",
  "data_config",
  "access_key",
  "name",
  "location",
];

const Dashboard = (props) => {
  const [guuid, setGuuid] = useState(null);
  const [openAddWidgetDialog, setOpenAddWidgetDialog] = useState(false);
  // const [openManageDashboardDialog, setOpenManageDashboardDialog] =
  //   useState(false);
  const [initialized, setInitialized] = useState(false);

  const [, setCookie] = useCookies(["ISX-user-session"]);

  const user = useSelector((state) => state.isx.user);

  // const refreshIntervals = useSelector((state) => state.isx.refreshIntervals);
  const dashboards = useSelector((state) => state.isx.dashboards);
  const widgets = useSelector((state) => state.isx.widgets);
  const widgetsLayouts = useSelector((state) => state.isx.widgetsLayouts);

  const interval = useRef(null);
  // const refreshInterval = useRef(null);
  const lastRefresh = useRef(0);

  const lastUserOrgids = React.useRef(null);
  const orgRefetchInterval = useRef(null);
  const lastUserOrgRefresh = React.useRef(0);

  const lastDashboardGuuid = useRef(null);
  const dashboardRefetchInterval = useRef(null);
  const lastDashboardRefetch = React.useRef(0);

  const params = useParams();
  const dashboardGuuid = params.dashboardGuuid;

  const dashboard = guuid && dashboards[guuid];

  const isxContext = useContext(ISXContext);
  const { addDashboard } = useContext(DashboardsContext);

  const orgs = useSelector((state) => state.isx.orgs);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      clearInterval(dashboardRefetchInterval.current);
      clearInterval(orgRefetchInterval.current);
    };
  }, []);

  useEffect(() => {
    clearInterval(orgRefetchInterval.current);
    orgRefetchInterval.current = setInterval(() => {
      if (lastUserOrgids.current?.length > 0) {
        const now = Date.now();
        if (now >= lastUserOrgRefresh.current + ORG_REFETCH_INTERVAL * 0.9) {
          lastUserOrgRefresh.current = now;
          dispatch(
            getOrgs(lastUserOrgids.current, isxContext.useOrganizationInvites)
          ).catch((e) => {
            console.warn("getOrgs exception", e);
          });
        } else {
          console.warn(
            "org fetch defer - too early by",
            ORG_REFETCH_INTERVAL - (now - lastUserOrgRefresh.current),
            "ms"
          );
        }
      }
    }, ORG_REFETCH_INTERVAL);
  }, [dispatch, isxContext.useOrganizationInvites]);

  useEffect(() => {
    const key = `ISX-user-session-${user.userid}`;
    let session = localStorage.getItem(key);
    if (!session) {
      session = uuidv4();
      localStorage.setItem(key, session);
    }
    setCookie("ISX-user-session", session, { sameSite: "strict" });
  }, [user, setCookie]);

  useEffect(() => {
    clearInterval(dashboardRefetchInterval.current);
    dashboardRefetchInterval.current = setInterval(() => {
      if (guuid) {
        const now = Date.now();
        if (
          now >=
          lastDashboardRefetch.current + DASHBOARD_REFETCH_INTERVAL * 0.9
        ) {
          lastDashboardRefetch.current = now;
          dispatch(getDashboard(guuid)).catch((e) => {
            console.error(e);
          });
        } else {
          console.warn(
            "dashboard fetch defer - too early by",
            DASHBOARD_REFETCH_INTERVAL - (now - lastDashboardRefetch.current),
            "ms"
          );
        }
      }
    }, DASHBOARD_REFETCH_INTERVAL);
  }, [guuid, dispatch]);

  useEffect(() => {
    const orgids = user?.orgs?.map((org) => org.orgid);
    if (!_isEqual(orgids, lastUserOrgids.current)) {
      lastUserOrgids.current = orgids;
      if (orgids?.length > 0) {
        const now = Date.now();
        lastRefresh.current = now;
        dispatch(getAllItems(orgids, ITEM_METADATA_FIELDS)).catch((e) => {
          console.warn("getAllItems exception", e);
        });
        lastUserOrgRefresh.current = now;
        dispatch(getOrgs(orgids, isxContext.useOrganizationInvites)).catch(
          (e) => {
            console.warn("getOrgs exception", e);
          }
        );
      }
    }

    // called on unmount
    return () => {
      clearInterval(interval.current);
    };
  }, [dispatch, isxContext.useOrganizationInvites, user]);

  useEffect(() => {
    lastDashboardRefetch.current = Date.now();
    dispatch(getDashboards(DASHBOARD_METADATA_FIELDS)).then(() => {
      setInitialized(true);
    });
  }, [dispatch]);

  useEffect(() => {
    // const computeRefreshInterval = () => {
    //   // const avgs = Object.values(refreshIntervals || {});
    //   // take minimum from accross all stacks for now, and round down
    //   // minimum of 1 second
    //   // need milliseconds so convert here
    //   return DEFAULT_FETCH_INTERVAL;
    //   // return avgs.length > 0
    //   //   ? (Math.round(Math.max(Math.min(...avgs), MIN_FETCH_INTERVAL)) || 1) *
    //   //       1000
    //   //   : DEFAULT_FETCH_INTERVAL;
    // };

    // const newInterval = computeRefreshInterval();
    // if (newInterval !== refreshInterval.current) {
    //   refreshInterval.current = newInterval;
    //   clearInterval(interval.current);
    //   interval.current = setInterval(() => {
    //     if (lastUserOrgids.current?.length > 0) {
    //       dispatch(
    //         getAllItems(lastUserOrgids.current, ITEM_METADATA_FIELDS)
    //       ).catch((e) => {
    //         console.warn("getAllItems exception", e);
    //       });
    //     }
    //   }, refreshInterval.current);
    // }

    clearInterval(interval.current);
    interval.current = setInterval(() => {
      if (lastUserOrgids.current?.length > 0) {
        const now = Date.now();
        if (now >= lastRefresh.current + ITEMS_FETCH_INTERVAL * 0.9) {
          lastRefresh.current = now;

          dispatch(
            getAllItems(lastUserOrgids.current, ITEM_METADATA_FIELDS)
          ).catch((e) => {
            console.warn("getAllItems exception", e);
          });
        } else {
          console.warn(
            "items fetch defer - too early by",
            ITEMS_FETCH_INTERVAL - (now - lastRefresh.current),
            "ms"
          );
        }
      }
    }, ITEMS_FETCH_INTERVAL);
  }, [dispatch]);

  useEffect(() => {
    const getDefaultDashboard = () => {
      // first check user for default dashboard - this is canonical place
      let guuid = user.session?.isx_favorite_dashboards?.[0];
      if (guuid) {
        window.location.hash = "#/dashboard/" + guuid;
      } else if (Object.keys(dashboards).length > 0) {
        // otherwise for backwards compatibility check dashboards for default;
        // this is deprecated and should be removed ASAP

        // have dashboards now...
        const defaults = Object.values(dashboards).filter((db) => db.isdefault);
        // just take first one if none marked as default
        guuid =
          defaults && defaults.length
            ? defaults[0].guuid
            : Object.keys(dashboards)[0];
        window.location.hash = "#/dashboard/" + guuid;
      } else {
        window.location.hash = "#/dashboard/";
      }
    };

    if (!initialized) {
      return;
    }

    if (dashboardGuuid) {
      if (dashboardGuuid !== lastDashboardGuuid.current) {
        //need to check if dashboard exists
        dispatch(getDashboard(dashboardGuuid)).then((results) => {
          if (results == null) {
            //no dashboard found, get default
            getDefaultDashboard();
          } else {
            setGuuid(dashboardGuuid);
          }
        }, getDefaultDashboard);
      }
    } else if (Object.keys(dashboards).length > 0) {
      // no dashboard guuid in url / router. grab the default
      getDefaultDashboard();
    }
    lastDashboardGuuid.current = dashboardGuuid;
  }, [user, dashboardGuuid, initialized, dashboards, dispatch]);

  const addCard = React.useCallback(() => setOpenAddWidgetDialog(true), []);

  const setAsDefault = React.useCallback(() => {
    dispatch(updateUser({ "session.isx_favorite_dashboards": [guuid] }));
    // Object.keys(dashboards).forEach((dguuid) => {
    //   if (dguuid !== guuid && dashboards[dguuid].isdefault) {
    //     dispatch(updateDashboard(dguuid, { isdefault: false }));
    //   }
    // });
    // dispatch(updateDashboard(guuid, { isdefault: true }));
  }, [dispatch, guuid]);

  // const manageDashboards = React.useCallback(
  //   () => setOpenManageDashboardDialog(true),
  //   []
  // );

  // const addDashboard = React.useCallback(
  //   (contents = null) => {
  //     const dashboard = contents || {
  //       widgets: [],
  //       widgets_layout: {},
  //     };
  //     return dispatch(createDashboard(dashboard)).then((rv) => {
  //       window.location.hash = "#/dashboard/" + rv.guuid;
  //     });
  //   },
  //   [dispatch]
  // );

  const setDashboardTitle = React.useCallback(
    (title) => {
      dispatch(updateDashboard(guuid, { title: title }));
    },
    [guuid, dispatch]
  );

  const removeCurrentDashboard = React.useCallback(() => {
    dispatch(removeDashboard(guuid)).then((rv) => {
      window.location.hash = "#/dashboard";
      setGuuid(null);
    });
  }, [dispatch, guuid]);

  const removeWidgetFromCurrentDashboard = React.useCallback(
    (wguuid) => {
      return dispatch(removeWidget(guuid, wguuid));
    },
    [dispatch, guuid]
  );

  const removeAddWidgetDialog = React.useCallback(
    () => setOpenAddWidgetDialog(false),
    []
  );

  // const removeManageDashboardDialog = React.useCallback(
  //   () => setOpenManageDashboardDialog(false),
  //   []
  // );

  const onDashboardChange = React.useCallback(
    (layout) => {
      dispatch(updateWidgetsLayout(guuid, layout));
    },
    [dispatch, guuid]
  );

  const isOwner = () => dashboard?.g_owner === user?.userid;

  const isDashboardAdmin = () => {
    if (isOwner()) {
      return true;
    }
    const adminingroup = (user, org) => {
      const uidx = org?.users.map((u) => u.userid).indexOf(user?.userid);
      if (uidx !== -1) {
        return new Set(org?.users[uidx]?.policies).has(ORG_ADMIN);
      }
      return false;
    };
    const dashboard_groups = dashboard?.acu_groups ?? [];
    return dashboard_groups.some((o) => adminingroup(user, orgs[o]));
  };
  const isReader = () => {
    if (isOwner()) {
      return true;
    }
    const groupReaders = dashboard?.acu_groups ?? [];
    const userGroups = new Set(user?.orgs?.map((o) => o.orgid));
    return groupReaders.some((o) => userGroups.has(o));
  };

  const isWriter = () => {
    if (isOwner()) {
      return true;
    }
    const groupWriters = dashboard?.acu_groups ?? [];
    const userGroups = new Set(user?.orgs?.map((o) => o.orgid));
    return groupWriters.some((o) => userGroups.has(o));
  };

  if (!initialized) {
    return null;
  }

  if (dashboard) {
    return (
      <DashboardComponent
        key={guuid}
        guuid={guuid}
        dashboard={dashboard}
        isOwner={isOwner()}
        isDashboardAdmin={isDashboardAdmin()}
        isReader={isReader()}
        isWriter={isWriter()}
        widgets={widgets}
        widgetsLayout={widgetsLayouts[guuid]}
        addCard={addCard}
        // manageDashboards={manageDashboards}
        setDashboardTitle={setDashboardTitle}
        setAsDefault={setAsDefault}
        addDashboard={addDashboard}
        removeDashboard={removeCurrentDashboard}
        removeWidget={removeWidgetFromCurrentDashboard}
        openAddWidgetDialog={openAddWidgetDialog}
        // openManageDashboardDialog={openManageDashboardDialog}
        removeAddWidgetDialog={removeAddWidgetDialog}
        // removeManageDashboardDialog={removeManageDashboardDialog}
        onDashboardChange={onDashboardChange}
        readOnly={user.readOnly}
      />
    );
  } else if (Object.keys(dashboards).length === 0) {
    return (
      !user.readOnly && (
        <Button
          variant="outlined"
          size="large"
          color="primary"
          onClick={() => addDashboard()}
          style={{ margin: 32 }}
        >
          Create A Dashboard
        </Button>
      )
    );
  } else {
    return null;
  }
};

const DashboardWrapper = (props) => {
  return (
    <TagEventIntervalsProvider>
      <TagsSubscriptionsProvider>
        <Dashboard {...props} />
      </TagsSubscriptionsProvider>
    </TagEventIntervalsProvider>
  );
};

export default DashboardWrapper;
